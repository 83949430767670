import React, { Component, Fragment } from 'react';
import { prop } from 'ramda';
import * as yup from 'yup';
import { withTranslation } from 'react-i18next';

import { PdaCheckboxes, Checkbox } from '@planka/common';

import withFormWrapper from '../hocs/withFormWrapper';
import Button from '../Button';

class PdaForm extends Component {
    render() {
        const { t, invalid, colors, submitText, isAudit } = this.props;

        return <Fragment>
            <PdaCheckboxes
                Checkbox={Checkbox}
                agreementLabel={isAudit ? t('pda.agreementLabelAudit') : t('pda.agreementLabel')}
                contractLabel={({ toggleModal }) => (
                    <span>
                        {t('pda.famWith')} <a href="#contract" onClick={toggleModal}>{t('pda.agreement')}</a>
                    </span>
                )}
                contractTitle={t('pda.contractTitle')}
                politicsLabel={({ toggleModal }) => (
                    <span>
                        {t('pda.famWith')} <a href="#politics" onClick={toggleModal}>{t('pda.politics')}</a>
                    </span>
                )}
                politicsTitle={t('pda.politicsTitle')}
            />
            <br />
            <Button type='submit' disabled={invalid} bg={colors.input} id='start'>
                {isAudit ? t('pda.startAudit') : (submitText || t('pda.start'))}
            </Button>
        </Fragment>;
    }
}

const validationSchema = () => yup.object().shape({
    agreement: yup.boolean().nullable().oneOf([true]).required(),
    politics: yup.boolean().nullable().oneOf([true]).required(),
    contract: yup.boolean().nullable().oneOf([true]).required(),
});

export default withFormWrapper(withTranslation()(PdaForm), {
    validationSchema,
    mapPropsToValues: prop('respondent')
});
