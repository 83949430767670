import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    background-color: ${({ bg }) => bg || '#f54d2e'};
    color: #fff;
    padding: 10px 16px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.33;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    user-select: none;
    &:hover {
        opacity: 0.9;
    }
    &:focus {
        outline: none;
    }
    &[disabled] {
        box-shadow: none;
        opacity: .55;
        pointer-events: none;
        cursor: not-allowed;
    }
`;

export default props =>
    <Button {...props}>
        { props.children }
    </Button>;
