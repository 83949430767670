import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { pathOr, path, pathEq, drop, findIndex, contains, addIndex, findLastIndex, update } from 'ramda';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Question } from '@planka/common';

import DocumentHeader from './DocumentHeader';
import Button from '../Button';
import AuditFormFields from '../forms/AuditFormFields';

const Title = styled.h2`
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 32px;
    position: relative;
    @media (max-width: 767px) {
        font-size: 28px;
    }
`;

const Container = styled.div`
    background: #fff;
    @media (max-width: 767px) {
        border: none;
    }
`;

const QuestionTitle = styled.div`
    padding: 15px;
    font-size: 1.3em;
    line-height: 1.5em;
    font-weight: normal;
    background-color: ${({ bg }) => bg || '#2b3d4f'};
    color: ${({ color }) => color || '#fff'};
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        margin: 0 -10px;
        padding: 15px 20px;
    }
`;

const PageContent = styled.div`
    padding: 15px;
    ol,
    ul {
        padding-left: 15px;
    }
`;

const PageButton = styled.div`
    padding: 15px;
`;

class QuestionComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPage: !!props.page
        };
    }

    componentDidUpdate(prev) {
        if (!pathEq(['page', 'questionNumber'], path(['page', 'questionNumber'], this.props), prev) && this.state.showPage !== !!this.props.page) {
            this.setState({ showPage: !!this.props.page });
        }
    }

    isAnswered = i => contains(path(['stat', i - 1], this.props.getResponse.data), ['answered', 'expired']);

    finishTest = () => this.props.postResponseFinish.dispatch(this.props.match.params.id);

    getNextIndex = () => {
        const { match: { params: { num } }, getResponse: { data }, stat } = this.props;
        const number = Number(num);
        const allowReturn = number === findLastIndex(x => !contains(x, ['answered', 'expired']), stat || []) && data.skipQuestionsAllowed;

        return addIndex(findIndex)(
            (x, i) => !contains(x, ['answered', 'expired']) && (allowReturn ? i !== number : true),
            allowReturn ? stat : drop(number + 1, stat)
        );
    }

    onSubmitSuccess = props => {
        const formState = props.form.getState();
        props.form.reset();

        const { match: { params: { id, num }}, getResponse: { data }, history, responseType, stat, setStat, paused } = this.props;
        const number = Number(num);
        const allowReturn = number === findLastIndex(x => !contains(x, ['answered', 'expired']), stat || []) && data.skipQuestionsAllowed;
        const nextIndex = this.getNextIndex();

        setStat(update(number, 'answered', stat), this.getNextIndex() <= -1 ? 'finished' : null);

        if (path(['values', 'pauseAfterQuestion'], formState) || paused) {
            history.push(`/${responseType}/${id}`);
        } else if (nextIndex > -1) {
            history.push(`/${responseType}/${id}/question/${allowReturn ? nextIndex : nextIndex + number + 1}`);
        }
    }

    renderQuestion() {
        const {
            getResponseQuestion: { data, meta },
            match: { params: { id, num }},
            getResponse: { data: respondent },
            history,
            responseType,
            postResponseAnswer,
            getResponse,
            stat,
            setPaused,
            t,
        } = this.props;
        const title = pathOr(respondent.title, ['infoScheme', 'title'], respondent);
        const questionNum = Number(num) + 1;
        const notLast = questionNum < respondent.questionsCount;
        const colors = pathOr({}, ['data', 'infoScheme', 'designScheme', 'colors'], getResponse);
        const allowReturn = Number(num) === findLastIndex(x => !contains(x, ['answered', 'expired']), stat || []);
        const skipNum = allowReturn ? this.getNextIndex() : this.getNextIndex() + questionNum;
        const isAudit = !!((responseType === 'audits') || data.auditComment || respondent.auditEnabled);

        return <div>
            <DocumentHeader title={`${t('test.question')} №${questionNum} | ${title}`} />
            <Title>{ title }</Title>
            <Question
                question={data}
                questionMeta={meta}
                respondent={respondent}
                colors={colors}
                questionNum={questionNum}
                isAudit={isAudit}
                additionalFormFields={isAudit && (
                    <AuditFormFields key={num} t={t} data={data} />
                )}
                onChangePage={i => ((i !== questionNum && !this.isAnswered(i) && respondent.skipQuestionsAllowed) || respondent.reAnswerQuestionAllowed) &&
                    history.push(`/${responseType}/${id}/question/${i - 1}`)}
                responseType={responseType}
                formAction={postResponseAnswer}
                finishTest={this.finishTest}
                skipUrl={respondent.skipQuestionsAllowed && notLast && skipNum > -1 ? `/${responseType}/${id}/question/${skipNum}` : null}
                pauseAllowed={respondent.pauseAllowed && notLast}
                onSubmitSuccess={this.onSubmitSuccess}
                finishAfterAnswer={this.getNextIndex() <= -1}
                num={num}
                id={id}
                stat={stat}
                setPaused={setPaused}
                translate={{
                    questionHelp: {
                        choice: t('test.questionHelp.choice'),
                        choices: t('test.questionHelp.choices'),
                        ranging: t('test.questionHelp.range'),
                        open: t('test.questionHelp.open'),
                    },
                    testsLink: t('test.testsLink'),
                    questionMessage: t('test.questionMessage'),
                    defaultErrorMessage: t('test.defaultErrorMessage'),
                    errorMessage: t('test.errorMessage'),
                    skipMessage: t('test.skipMessage'),
                    submitMessage: isAudit ? t('test.auditMessage') : t('test.submitMessage'),
                    nextPauseMessage: t('test.nextPauseMessage'),
                    finishMessage: t('test.finishMessage'),
                    confirmTitleMessage: t('test.confirmTitleMessage'),
                    confirmMessage: t('test.confirmMessage'),
                    confirmOkMessage: t('test.confirmOkMessage'),
                    confirmCancelMessage: t('test.confirmCancelMessage'),
                    rangingPlaceholderMessage: t('test.rangingPlaceholderMessage'),
                    auditOpenAnswerMessage: t('audit.auditOpenAnswerMessage'),
                }}
            />
        </div>;
    }

    continueTest = () => this.setState({ showPage: false });

    renderPage = () => {
        const { t, page, getResponse } = this.props;
        const colors = pathOr({}, ['data', 'infoScheme', 'designScheme', 'colors'], getResponse);

        return <Container>
            <QuestionTitle bg={colors.questionHeader} color={colors.questionHeaderText}>{ page.title }</QuestionTitle>
            <PageContent dangerouslySetInnerHTML={{ __html: page.content }} />
            <PageButton>
                <Button onClick={this.continueTest}>{t('test.continue')}</Button>
            </PageButton>
        </Container>;
    }

    render() {
        return this.state.showPage ? this.renderPage() : this.renderQuestion();
    }
}

export default withTranslation()(withRouter(withAsyncActions(({ postResponseFinishAction, getResponseQuestionAction, postResponseAnswerAction, responseType }) => ({
    postResponseFinish: postResponseFinishAction
        .withSuccessHandler(({ isAudit, history, setStat, stat }) => {
            setStat(stat, 'finished');
            isAudit && history.push('/audits/success');
        }),
    getResponseQuestion: getResponseQuestionAction
        .withParams(({ match: { params: { id, num }}}) => `${id}.${num}`)
        .withPayload(({ match: { params: { id, num }}}) => ({
            id,
            num
        }))
        .withSuccessHandler(props => {
            const { getResponse: { data }, responseType, getResponseQuestion, postResponseFinish, stat, setStat, setCurrentQuestion } = props;
            const { startTimer } = props;
            const { history, match: { params: { id, num } } } = props;
            const number = Number(num);
            const isAudit = !!((responseType === 'audits') || data.auditComment || data.auditEnabled);

            setCurrentQuestion(number);

            if (stat[number] === 'not viewed') {
                setStat(update(number, 'skipped', stat));
            }

            if (!isAudit && data.timeLimited && data.timeLimitedBy === 'question') {
                if (getResponseQuestion.data.timeLeft) {
                    startTimer(getResponseQuestion.data.timeLeft, num);
                } else {
                    const nextIndex = findIndex(x => !contains(x, ['answered', 'expired']), drop(number + 1, stat));

                    if (nextIndex > -1) {
                        setStat(update(number, 'expired', stat));
                        history.push(`/${responseType}/${id}/question/${nextIndex + number + 1}`);
                    } else {
                        postResponseFinish.dispatch(id);
                    }
                }
            }
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true }),
    postResponseAnswer: postResponseAnswerAction
        .withOptions({ resetOnUnmount: true })
}))(QuestionComponent)));
