import React, { Component } from 'react';
import moment from 'moment';
import { findIndex, contains, drop, update } from 'ramda';
import { withRouter } from 'react-router-dom';

import { withAsyncActions } from 'react-async-client';

export default WrappedComponent => {
    class TimerComponent extends Component {
        state = {
            seconds: 0,
            timer: false,
            question: null,
            endTime: null
        };

        startTimer = (seconds, question) => {
            const endTime = moment().add(seconds + 1, 'seconds');
            this.setState({ endTime, seconds, timer: true, question });

            clearInterval(this.interval);
            this.interval = setInterval(() =>
                this.setState(prev => {
                    const nextSeconds = this.state.endTime.diff(moment(), 'seconds');

                    if (nextSeconds <= 0 && prev.seconds > 0) {
                        const {
                            getResponse: { data: { timeLimitedBy }},
                            history,
                            postResponseFinish,
                            match: { params: { id } },
                            responseType,
                            setStat,
                            stat
                        } = this.props;
                        const number = Number(prev.question);
                        const next = this.getNextQuestion(number);

                        if (timeLimitedBy === 'question' && next > -1) {
                            setStat(update(number, 'expired', stat));
                            history.push(`/${responseType}/${id}/question/${next + number + 1}`);
                        } else {
                            postResponseFinish.dispatch(id);
                        }
                    }

                    return { seconds: nextSeconds > 0 ? nextSeconds : 0 };
                }), 1000)
        }

        stopTimer = () => {
            if (this.state.timer) {
                clearInterval(this.interval);
                this.setState({ timer: false, seconds: 0, question: null, endTime: null });
            }
        }

        getNextQuestion = number => findIndex(x => !contains(x, ['answered', 'expired']), drop(number + 1, this.props.stat || []));;

        render() {
            return <WrappedComponent
                {...this.props}
                startTimer={this.startTimer}
                stopTimer={this.stopTimer}
                timer={this.state.timer}
                seconds={this.state.seconds}
                renderTimer={this.renderTimer} />;
        }
    }

    return withRouter(withAsyncActions(({ getResponseAction, postResponseFinishAction }) => ({
        getResponse: getResponseAction,
        postResponseFinish: postResponseFinishAction
            .withSuccessHandler(({ isAudit, history, setStat, stat }) => {
                setStat(stat, 'finished');
                isAudit && history.push('/audits/success');
            })
            .withOptions({ resetOnUnmount: true })
    }))(TimerComponent))
}
