import React from 'react';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { downloadRespondentsPilotPdf } from '@planka/common/lib/utils/respondentsPilotPdf';
import { getRespondentReport } from '../actions/asyncActions';
import { message } from 'antd';
import Loader from './Loader';
import Wrapper from './Wrapper';

const LoaderContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 16px;
    line-height: 1.7em;
    margin-bottom: 75px;
`;

const Description = styled.h2`
    font-weight: 300;
    font-size: 14px;
`;

const VerticalCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 130px);
`;

const RespondentReport = props => {
    const handleDownload = () => {
        downloadRespondentsPilotPdf(props.getRespondentReport.data);
    };

    return props.getRespondentReport.meta.pending ?
        <LoaderContainer>
            <Loader />
        </LoaderContainer>
        : <Wrapper>
            <VerticalCenter>
                <Title>{props.t('test.reportTitle')}</Title>
                { props.getRespondentReport.meta.error ?
                    <Description>{props.t('test.reportError')}</Description>
                    : <Description>
                        <a href="#download" onClick={handleDownload}>{props.t('test.reportDescription')}</a>
                    </Description>
                }
            </VerticalCenter>
        </Wrapper>;
}

export default withTranslation()(withAsyncActions({
    getRespondentReport: getRespondentReport
        .withPayload(({ match }) => match.params.id)
        .withSuccessHandler(({ getRespondentReport: { data } }) => {
            window.pdfMake = require('pdfmake/build/pdfmake.min');
            const robotoVft = require('pdfmake/build/vfs_fonts');
            window.pdfMake.vfs = robotoVft.pdfMake.vfs;

            downloadRespondentsPilotPdf(data);
        })
        .withErrorHandler(({ t }) => {
            message.error(t('test.reportError'));
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(RespondentReport));
