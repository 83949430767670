"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.DEFAULT_ERROR = void 0;
var DEFAULT_ERROR = 'Ошибка соединения с сервером';
exports.DEFAULT_ERROR = DEFAULT_ERROR;
var _default = {
  'Respondent has no solution.': 'У респондента нет решения',
  'You can answer only the current question.': 'Вы можете отвечать только на текущий вопрос',
  'You have already answered this question.': 'Вы уже ответили на этот вопрос',
  'Time for question has expired.': 'Время ответа на вопрос истекло',
  'Time for solution has expired.': 'Время теста истекло',
  'The answer variant ids are not from answer variants.': 'Выбранного варианта нет среди доступных. Попробуйте обновить страницу'
};
exports["default"] = _default;