import 'yup';
import { setLocale } from 'yup';
import i18n from 'i18next';

export const validationLocale = t => ({
    mixed: {
        required: t('form.validation.mixed.required'),
    },
    string: {
        required: t('form.validation.mixed.required'),
        email: t('form.validation.string.email'),
    },
    number: {
        required: t('form.validation.mixed.required'),
    },
    date: {
        required: t('form.validation.mixed.required'),
    },
    array: {
        required: t('form.validation.mixed.required'),
    }
});

export function buildYupLocale(_, t) {
    setLocale(validationLocale(t));
}

export const t = i18n.t.bind(i18n);
