import React, { useCallback, useEffect, useRef } from 'react';
import { path, filter, pathOr, addIndex } from 'ramda';

const filterIndexed =  addIndex(filter);

const withFieldWrapper = WrappedComponent => props => {
    const onChange = useCallback(value => props.input.onChange(value), [props.input]);
    const isHiddenQuestion = (question, index) => {
        const { respondent, hiddenQuestionTemplate, questionNumber } = props;

        return hiddenQuestionTemplate ?
            hiddenQuestionTemplate.render({
                question,
                questionNumber,
                questionIndex: index,
                additionalData: respondent.additionalData,
                project: respondent.publicProject,
                answers: path(['response', 'answers'], respondent)
            }) === 'true' : false;
    };

    const originalQuestions = pathOr([], ['questions'], props.question);
    const questions = filterIndexed((item, index) => !isHiddenQuestion(item, index), originalQuestions);
    const translatedQuestions = filterIndexed((item, index) => !isHiddenQuestion(item, index), originalQuestions);
    const onUnmount = useCallback(() => {
        if (props.clearOnUnmount) {
            onChange(null);
        }
    }, [props.clearOnUnmount, onChange]);
    const val = useRef();
    useEffect(() => {
        val.current = props;
    }, [props]);

    useEffect(() => {
        return () => onUnmount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val]);

    return <div className='form-item'>
        { props.fieldType !== 'checkbox' && <div className='form-label' dangerouslySetInnerHTML={{ __html: props.label }} /> }
        <WrappedComponent
            {...props}
            questions={questions}
            translatedQuestions={translatedQuestions}
            originalQuestions={originalQuestions}
            onChange={onChange} />
    </div>;
}

export default withFieldWrapper;
