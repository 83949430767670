import React, { Component } from 'react';

import { getRespondent, postRespondentPda, postRespondentStart, postRespondentFinish, getRespondentQuestion, postRespondentAnswer, postRespondentStartFinish } from '../actions/asyncActions';

import Response from './test/Response';

export default class Respondent extends Component {
    render() {
        return (
            <Response
                {...this.props}
                responseType='respondents'
                getResponseAction={getRespondent}
                postResponseFinishAction={postRespondentFinish}
                postResponsePdaAction={postRespondentPda}
                postResponseStartAction={postRespondentStart}
                getResponseQuestionAction={getRespondentQuestion}
                postResponseAnswerAction={postRespondentAnswer}
                postResponseStartFinishAction={postRespondentStartFinish}
            />
        );
    }
}
