import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { assocPath, pathOr } from 'ramda';
import styled from 'styled-components';
import * as yup from 'yup';
import { withTranslation } from 'react-i18next';

import { PdaCheckboxes } from '@planka/common';

import Checkbox from './formComponents/Checkbox';
import Button from '../../Button';
import { ButtonLoader } from '../../Loader';

const FormComponent = styled.form`
    width: 100% !important;
    padding: 0 0 0 40px;
    @media (max-width: 812px) {
        padding: 0;
    }

    .form-item, p {
        margin-bottom: 20px;
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        padding: 10px 0;
    }
`;

const validationSchema = () => yup.object().shape({
    agreement: yup.boolean().nullable().oneOf([true]).required(),
    politics: yup.boolean().nullable().oneOf([true]).required(),
    contract: yup.boolean().nullable().oneOf([true]).required(),
});

export default withTranslation()(class SurveyRespondentForm extends Component {
    onSubmit = data => {
        this.props.postSurveyRespondentPda.dispatch({
            id: this.props.id,
            data,
        });
    }

    validate = values => {
        const schema = validationSchema();

        if (!schema) {
            return {};
        }

        try {
            schema.validateSync(values, { abortEarly: false });
        } catch (e) {
            return e.inner.reduce((errors, error) => {
                const path = error.path.split(/\.|\].|\[/).map(p => isNaN(Number(p)) ? p : Number(p));
                return assocPath(path, error.message, errors);
            }, {});
        }
    }

    render() {
        const { t, language, respondent, postSurveyRespondentPda } = this.props;
        const buttonTranslation = pathOr('Продолжить', ['translation', 'pageElements', 'continueButton'], respondent.publicSurvey);

        return (
            <Form
                onSubmit={this.onSubmit}
                validate={this.validate}
                render={({ handleSubmit, invalid }) =>
                    <FormComponent onSubmit={handleSubmit}>
                        <p>{t('pda.surveyTitle')}</p>
                        <PdaCheckboxes
                            Checkbox={Checkbox}
                            agreementLabel={t('pda.surveyAgreementLabel')}
                            contractLabel={({ toggleModal }) => (
                                <span>
                                    {t('pda.famWith')} <a href="#contract" onClick={toggleModal}>{t('pda.agreement')}</a>
                                </span>
                            )}
                            contractTitle={t('pda.contractTitle')}
                            politicsLabel={({ toggleModal }) => (
                                <span>
                                    {t('pda.famWith')} <a href="#politics" onClick={toggleModal}>{t('pda.politics')}</a>
                                </span>
                            )}
                            politicsTitle={t('pda.politicsTitle')}
                        />
                        <br />
                        <Footer>
                            <Button type='submit' disabled={invalid || postSurveyRespondentPda.meta.pending} id='start'>
                                <ButtonLoader loading={postSurveyRespondentPda.meta.pending}>
                                    { language ? pathOr(buttonTranslation, ['publicSurvey', 'translation', language, 'continueButton'], respondent) : buttonTranslation}
                                </ButtonLoader>
                            </Button>
                        </Footer>
                    </FormComponent>
                }
            />
        );
    }
})
