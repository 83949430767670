import form from './ru/form.json';
import app from './ru/app.json';
import company from './ru/company.json';
import pda from './ru/pda.json';
import test from './ru/test.json';
import survey from './ru/survey.json';
import audit from './ru/audit.json';

export default {
    app,
    form,
    company,
    pda,
    test,
    survey,
    audit,
};
