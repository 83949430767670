export const GET_TESTS = 'GET_TESTS';
export const GET_TESTS_PDA = 'GET_TESTS_PDA';
export const POST_TESTS_PDA = 'POST_TESTS_PDA';

export const GET_RESPONDENT = 'GET_RESPONDENT';
export const POST_RESPONDENT_PDA = 'POST_RESPONDENT_PDA';
export const POST_RESPONDENT_START = 'POST_RESPONDENT_START';
export const POST_RESPONDENT_FINISH = 'POST_RESPONDENT_FINISH';
export const GET_RESPONDENT_QUESTION = 'GET_RESPONDENT_QUESTION';
export const POST_RESPONDENT_ANSWER = 'POST_RESPONDENT_ANSWER';
export const POST_RESPONDENT_START_FINISH = 'POST_RESPONDENT_START_FINISH';
export const GET_RESPONDENT_REPORT = 'GET_RESPONDENT_REPORT';

export const GET_AUDIT = 'GET_AUDIT';
export const POST_AUDIT_START = 'POST_AUDIT_START';
export const POST_AUDIT_FINISH = 'POST_AUDIT_FINISH';
export const GET_AUDIT_QUESTION = 'GET_AUDIT_QUESTION';
export const POST_AUDIT_ANSWER = 'POST_AUDIT_ANSWER';
export const POST_AUDIT_START_FINISH = 'POST_AUDIT_START_FINISH';

export const GET_SURVEY = 'GET_SURVEY';
export const GET_SURVEY_RESPONDENT = 'GET_SURVEY_RESPONDENT';
export const POST_SURVEY_RESPONDENT_PDA = 'POST_SURVEY_RESPONDENT_PDA';
export const POST_SURVEY_RESPONDENT_START = 'POST_SURVEY_RESPONDENT_START';
export const POST_SURVEY_RESPONDENT_FINISH = 'POST_SURVEY_RESPONDENT_FINISH';
export const GET_SURVEY_RESPONDENT_QUESTION = 'GET_SURVEY_RESPONDENT_QUESTION';
export const PUT_SURVEY_RESPONDENT_QUESTION = 'PUT_SURVEY_RESPONDENT_QUESTION';
export const POST_SURVEY_RESPONDENT_START_FINISH = 'POST_SURVEY_RESPONDENT_START_FINISH';

export const GET_REGIONS = 'GET_REGIONS';
export const GET_SPECIALITIES = 'GET_SPECIALITIES';

export const GET_COMPANY = 'GET_COMPANY';
