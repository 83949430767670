import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { path, pathOr } from 'ramda';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { getTests, getTestsPda, postTestsPda } from '../actions/asyncActions';
import PdaForm from './forms/PdaForm';
import Wrapper from './Wrapper';
import ExecutionTime from './test/ExecutionTime';

const Title = styled.h2`
    font-weight: 300;
    font-size: 1.3em;
    text-shadow: 0 1px 1px rgba(77,77,77,.32);
`;

const Table = styled.div`
    margin-top: 25px;
    padding: 15px;
    background: #fff;
    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        border-spacing: 0;
        border-collapse: collapse;
        thead {
            th {
                vertical-align: middle;
                border-top: 0;
                border-bottom: 2px solid #ddd;
                font-size: 12px;
                color: #7B7A7A;
                font-weight: 300;
                padding: 8px 2px;
                line-height: 1.42857143;
                &:first-child {
                    text-align: left;
                    width: auto;
                }
                &:last-child {
                    text-align: right;
                }
                @media (max-width: 767px) {
                    font-size: 10px;
                }
            }
        }
        tbody {
            td {
                padding: 8px 4px;
                line-height: 1.42857143;
                vertical-align: middle;
                border-top: 1px solid #ddd;
                font-size: 14px;
                text-align: center;
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
        }
    }
    @media (max-width: 767px) {
        box-shadow: none;
        margin-top: 0;
        padding: 15px 0;
    }
`;

const Empty = styled.div`
    font-size: 1.3em;
    text-align: center;
`;

const Status = styled.div`
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: ${({ status }) => status === 'finished' ? '#5cb85c' : status === 'started' ? '#d9534f' : '#777'};
`;

class Tests extends Component {
    getStatus = (item, t) => {
        return item.status === 'finished' ? t('test.finished') :
            item.status === 'started' ? (item.paused ? t('test.paused') : t('test.started')) : t('test.notfinished');
    }

    renderTableData = (data, t) => {
        return <table>
            <thead>
                <tr>
                    <th>{t('test.name')}</th>
                    <th>{t('test.questions')}</th>
                    <th>{t('test.time')}</th>
                    <th>{t('test.skiped')}</th>
                    <th>{t('test.pause')}</th>
                    <th>{t('test.status')}</th>
                </tr>
            </thead>
            <tbody>
                { data.map(item =>
                    <tr key={item.id}>
                        <td>
                            <Link to={{
                                pathname: `/respondents/${item.id}`,
                                state: {
                                    respondentName: item.respondentName
                                }
                            }}>{ item.title }</Link>
                        </td>
                        <td>{ item.questionsCount }</td>
                        <td>{ item.auditEnabled ? '-' : <ExecutionTime executionTime={item.executionTime} timeLimited={item.timeLimited} /> }</td>
                        <td>{ item.skipQuestionsAllowed ? t('test.yes') : t('test.no') }</td>
                        <td>{ item.pauseAllowed ? t('test.yes') : t('test.no') }</td>
                        <td>
                            <Status status={item.status}>
                                { this.getStatus(item, t) }
                            </Status>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
    }

    render() {
        const { t, match, getTests: { data, meta }, getTestsPda: { data: pdaData, meta: pdaMeta }, postTestsPda } = this.props;
        const hasPda = pathOr(false, ['agreement'], pdaData);
        const colors = pathOr({}, [0, 'infoScheme', 'designScheme', 'colors'], data);

        if (meta.error || pdaMeta.error) {
            return <Wrapper>
                <Title>{t('app.error')}</Title>
            </Wrapper>;
        }

        return <Wrapper headerName={path([0, 'respondentName'], data)} pending={(meta.pending && !meta.lastSucceedAt) || pdaMeta.pending}>
            { !hasPda
                ? <PdaForm
                    formAction={postTestsPda}
                    respondent={{
                        code: match.params.code,
                        company: match.params.company
                    }}
                    colors={colors}
                    submitText={t('test.testsLink')}
                />
                : <Fragment>
                    <Title>{t('test.tests')}</Title>
                    <Table>
                        { data.length ?
                            this.renderTableData(data, t) :
                            <Empty>{t('test.noTests')}</Empty>
                        }
                    </Table>
                </Fragment>}
        </Wrapper>;
    }
}

export default withAsyncActions({
    postTestsPda: postTestsPda
        .withSuccessHandler(({ getTestsPda }) => {
            getTestsPda.refresh();
        }),
    getTestsPda: getTestsPda
        .withPayload(({ match }) => ({
            code: match.params.code,
            company: match.params.company
        }))
        .withOptions({ dispatchOnMount: true }),
    getTests: getTests
        .withPayload(({ match }) => ({
            code: match.params.code,
            company: match.params.company
        }))
        .withOptions({ dispatchOnMount: true })
})(withTranslation()(Tests));
