import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const RadioButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
`;

const RadioButton = styled.label`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: center;
    border: 1px solid #ccc;
    margin-left: -1px;
    padding: 8px 12px 9px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    
    &:first-child{
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    
    &:last-child{
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    ${({ checked }) => checked && css`
        background-color: #2B3D4F;
        color: #fff;
    `}

    input {
        display: none;
    }
`;

class RadioButtons extends Component {
    static defaultProps = {
        options: [],
        namePath: 'name',
        valuePath: 'id',
        inline: true
    };

    render() {
        const { options, input: { name, value }, onChange, namePath, valuePath } = this.props;

        return (
            <RadioButtonsContainer>
                { options.map((option, index) => {
                    const checked = option[valuePath] === value;
                    return (
                        <RadioButton key={`${name}-${index}`} checked={checked}>
                            <input
                                id={`${name}-${index}`}
                                type='radio'
                                onChange={() => onChange(option[valuePath])}
                                checked={checked}
                            />
                            <div dangerouslySetInnerHTML={{ __html: option[namePath] }} />
                        </RadioButton>
                    );
                })}
            </RadioButtonsContainer>
        );
    }
}

export default withFieldWrapper(RadioButtons);
