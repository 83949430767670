import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { prop, fromPairs } from 'ramda';
import * as yup from 'yup';
import { Radio } from '@planka/common';
import { withTranslation } from 'react-i18next';

import withFormWrapper from '../hocs/withFormWrapper';
import NumberInput from './formComponents/NumberInput';
import Select from './formComponents/Select';
import Button from '../Button';
import Input from './formComponents/Input';

const FIELDS = {
    string: () => Input,
    number: () => NumberInput,
    choice: field => field.settings.choiceType === 'select' ? Select : Radio
};

class RespondentForm extends Component {
    renderField = field => {
        const Component = FIELDS[field.type](field);

        return <Field
            key={field.code}
            name={`answers.${field.code}`}
            component={Component}
            label={field.name}
            options={field.type === 'choice' ? field.choices.map(id => ({ id, name: id })) : null}
            colors={this.props.colors} />;
    }

    render() {
        const { t, invalid, respondent, colors } = this.props;

        return <Fragment>
            { respondent.respondentFormFields.map(this.renderField)}
            <Button type='submit' disabled={invalid} bg={colors.input} id='start'>
                {t('test.start')}1
            </Button>
        </Fragment>;
    }
}

const validationSchema = props => yup.object().shape({
    answers: yup.object().shape({
        ...(fromPairs(props.respondent.respondentFormFields.map(field => ([field.code, field.type === 'number' ? yup.number().required() : yup.string().required()]))))
    })
});

export default withFormWrapper(withTranslation()(RespondentForm), {
    validationSchema,
    mapPropsToValues: prop('respondent')
});
