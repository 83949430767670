import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { QuestionMark } from '@styled-icons/boxicons-regular/QuestionMark';
import { Timer } from '@styled-icons/material/Timer';
import { ArrowForward } from '@styled-icons/typicons/ArrowForward';
import { Pause } from '@styled-icons/material/Pause';
import { path, pathOr, findIndex, contains, range } from 'ramda';
import { withRouter } from 'react-router';
import { withAsyncActions } from 'react-async-client';
import { withTranslation } from 'react-i18next';

import DocumentHeader from './DocumentHeader';
import ExecutionTime from './ExecutionTime';
import Button from '../Button';
import RespondentForm from '../forms/RespondentForm';
import PdaForm from '../forms/PdaForm';
import { DEFAULT_TEST_DESCRIPTION, DEFAULT_TEST_DESCRIPTION_EXPERT } from '../../constants/test';

const Container = styled.div`
    @media (max-width: 720px) {
        margin-top: 0;
    }
`;

const Title = styled.h1`
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 32px;
    position: relative;
    @media (max-width: 767px) {
        font-size: 28px;
    }
`;

const InfoBlock = styled.div`
    margin-bottom: 15px;
    ${({ showForm }) => showForm ? css`
        border-bottom: 1px solid #ccc;
    ` : css`
        display: table;
        width: 100%;
        padding: 10px 0;
        text-align: center;
    `}
    &:after {
        clear: both;
        content: " ";
        display: table;
    }
`;

const InfoItem = styled.div`
    ${({ showForm }) => showForm ? css`
        margin-bottom: 10px;
        float: left;
        width: 50%;
        @media (max-width: 767px) {
            width: 100%;
        }
    ` : css`
        display: table-cell;
        width: auto;
        float: none;
        border-top: 1px dashed ${({ border }) => border || '#F4F4F4'};
        padding: 15px 0 0;
        text-align: left;
        @media (max-width: 767px) {
            display: block;
            border: 0;
        }
    `}
`;

const InfoValue = styled.span`
    font-weight: 400;
`;

const InfoTitle = styled.span`
    font-size: 12px;
    margin: 0 10px;
`;

const IconWrapper = styled.div`
    color: #646464;
    background-color: ${({ bg }) => bg || '#F3F3F3'};
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 5px;
    svg{
        width: 20px;
        height: 20px;
    }
`;

const Description = styled.div`
    font-size: 16px;
    line-height: 1.7em;
    p {
        margin: 1em 0;
    }
    b {
        font-weight: 400;
    }
    ol, ul {
        margin-bottom: 10px;
        padding-left: 40px;
    }
`;

const Left = styled.div`
    ${({ showForm }) => showForm && css`
        @media (min-width: 720px) {
            width: 49%;
            float: left;
            padding-right: 30px;
        }
    `}
`;

const Right = styled.div`
    ${({ showForm }) => showForm && css`
        @media (min-width: 720px) {
            width: 49%;
            float: right;
            padding-left: 30px;
        }
    `}
`;

class StartTest extends Component {
    startTest = () => {
        const { respondent, history, postResponseStart, responseType, postResponseStartFinish, status, stat, currentQuestion } = this.props;

        if (respondent.questionsCount < 1) {
            postResponseStartFinish.dispatch(respondent);
        } else if (status === 'started') {
            const currentAnswered = contains(stat[currentQuestion], ['answered', 'expired']);
            const currentQuestionIndex = findIndex(x => !contains(x, ['answered', 'expired']), stat);

            history.push(`/${responseType}/${respondent.id}/question/${currentAnswered ? (currentQuestionIndex < 0 ? 0 : currentQuestionIndex) : currentQuestion}`);
        } else {
            postResponseStart.dispatch({ id: respondent.id });
        }
    }

    render() {
        const { t, respondent, postResponseStart, postResponseStartFinish, title, postResponseStartFinishAction, status, responseType } = this.props;
        const isAudit = !!((responseType === 'audits') || respondent.auditEnabled);
        const description = pathOr(isAudit ? DEFAULT_TEST_DESCRIPTION_EXPERT : DEFAULT_TEST_DESCRIPTION, ['infoScheme', 'description'], respondent);
        const showForm = respondent.formRequired;
        const colors = pathOr({}, ['infoScheme', 'designScheme', 'colors'], respondent);

        return <Container>
            <DocumentHeader title={title} />
            <Left showForm={showForm}>
                <Title>{ title }</Title>
                <Description dangerouslySetInnerHTML={{ __html: description }} />
            </Left>
            <Right showForm={showForm}>
                <InfoBlock showForm={showForm}>
                    <InfoItem showForm={showForm} border={colors.elementStatistic}>
                        <IconWrapper bg={colors.elementStatistic}><QuestionMark size={15} /></IconWrapper>
                        <InfoTitle>{t('test.questions')}:</InfoTitle>
                        <InfoValue>{ respondent.questionsCount }</InfoValue>
                    </InfoItem>
                    <InfoItem showForm={showForm} border={colors.elementStatistic}>
                        <IconWrapper bg={colors.elementStatistic}><Timer size={15} /></IconWrapper>
                        <InfoTitle>{t('test.time')}:</InfoTitle>
                        <InfoValue><ExecutionTime executionTime={respondent.executionTime} timeLimited={respondent.timeLimited} /></InfoValue>
                    </InfoItem>
                    <InfoItem showForm={showForm} border={colors.elementStatistic}>
                        <IconWrapper bg={colors.elementStatistic}><ArrowForward size={15} /></IconWrapper>
                        <InfoTitle>{t('test.skip')}:</InfoTitle>
                        <InfoValue>{ respondent.skipQuestionsAllowed ? t('test.yes') : t('test.no') }</InfoValue>
                    </InfoItem>
                    <InfoItem showForm={showForm} border={colors.elementStatistic}>
                        <IconWrapper bg={colors.elementStatistic}><Pause size={15} /></IconWrapper>
                        <InfoTitle>{t('test.pause')}:</InfoTitle>
                        <InfoValue>{ respondent.pauseAllowed ? t('test.yes') : t('test.no') }</InfoValue>
                    </InfoItem>
                </InfoBlock>
                { (showForm && status === 'sent') ?
                    <RespondentForm
                        formAction={respondent.questionsCount < 1 ? postResponseStartFinishAction : postResponseStart}
                        respondent={respondent}
                        colors={colors} /> :
                    status === 'sent' && !path(['pda', 'agreement'], respondent) ?
                        <PdaForm
                            formAction={respondent.questionsCount < 1 ? postResponseStartFinishAction : postResponseStart}
                            respondent={respondent}
                            colors={colors}
                            isAudit={isAudit}
                        /> :
                        <Button onClick={this.startTest} bg={colors.input} loading={postResponseStart.meta.pending || postResponseStartFinish.meta.pending} id='start'>
                            { status === 'started' ? t('test.next') : isAudit ? t('test.startAudit') : t('test.start') }
                        </Button>
                }
            </Right>
        </Container>;
    }
}

export default withRouter(
    withAsyncActions(({ postResponseStartAction, responseType, postResponseStartFinishAction }) => ({
        postResponseStart: postResponseStartAction
            .withSuccessHandler(({ responseType, respondent, history, setStat, startTimer, timer }) => {
                if (respondent.questionsCount) {
                    setStat(range(0, respondent.questionsCount).map(() => 'not viewed'), 'started');

                    const isAudit = !!((responseType === 'audits') || respondent.auditEnabled);

                    if (!isAudit && !timer && respondent.timeLimited && respondent.timeLimitedBy === 'test') {
                        startTimer(respondent.executionTime);
                    }

                    history.push(`/${responseType}/${respondent.id}/question/0`);
                }
            })
            .withOptions({ resetOnUnmount: true }),
        postResponseStartFinish: postResponseStartFinishAction
            .withSuccessHandler(({ setStat }) => setStat(null, 'finished'))
            .withOptions({ resetOnUnmount: true })
    }))(withTranslation()(StartTest))
);
