import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Wrapper from './Wrapper';

const VerticalCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 130px);
`;

const ErrorCode = styled.div`
    margin-bottom: 30px;
    svg {
        max-width: 90%;
    }
`;

const NotFound = styled.h2`
    font-weight: 300;
    font-size: 2em;
    color: #2c3d4e;
`;

export default withTranslation()(class NoMatch extends Component {
    render() {
        return <Wrapper>
            <VerticalCenter>
                <ErrorCode>
                    <svg width="390" height="148" viewBox="0 0 390 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M172.349 0H247L194.651 74H120L172.349 0Z" fill="#F54D2E"/>
                        <path d="M194.349 74H269L216.651 148H142L194.349 74Z" fill="#2B3D4F"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M99.2 144.5H99.7V109.499H120.3V85.299H99.7V3.50001H70.565L70.415 3.68101L0.615 87.881L0.5 88.02V109.5H70.7V144.5H99.2ZM162.225 136.799L162.227 136.801C171.652 143.802 182.625 147.299 195.125 147.299C213.675 147.299 228.424 140.097 239.322 125.703C250.331 111.472 255.825 94.228 255.825 73.999C255.825 53.771 250.331 36.527 239.322 22.295C228.424 7.90101 213.675 0.700012 195.125 0.700012C182.892 0.700012 171.989 4.13001 162.433 10.994L162.431 10.996C153.02 17.852 145.899 26.926 141.065 38.203V38.205C136.502 48.941 134.225 60.875 134.225 74C134.225 87.26 136.569 99.329 141.266 110.199L141.268 110.203C146.101 121.078 153.087 129.947 162.225 136.8V136.799ZM217.504 106.329L217.503 106.331C211.985 115.001 204.537 119.299 195.125 119.299C185.848 119.299 178.33 115.003 172.543 106.325C166.873 97.488 164.025 86.721 164.025 73.999C164.025 61.139 166.875 50.442 172.542 41.875L172.546 41.869C178.199 33.061 185.716 28.699 195.125 28.699C204.534 28.699 211.982 33.06 217.501 41.865L217.508 41.875C223.175 50.442 226.025 61.14 226.025 73.999C226.025 86.723 223.176 97.492 217.504 106.329ZM368.341 144.5H368.841V109.499H389.441V85.299H368.841V3.50001H339.706L339.556 3.68101L269.756 87.881L269.641 88.02V109.5H339.841V144.5H368.341ZM70.7 85.299H33.875L70.7 41.57V85.3V85.299ZM339.841 85.299H303.015L339.841 41.569V85.3V85.299Z" stroke="#2B3D4F"/>
                        <mask id="mask0_646_3482" maskUnits="userSpaceOnUse" x="142" y="74" width="127" height="74">
                            <path d="M194.349 74H269L216.651 148H142L194.349 74Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_646_3482)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M99.2 144.5H99.7V109.499H120.3V85.299H99.7V3.50002H70.565L70.415 3.68102L0.615005 87.881L0.5 88.02V109.5H70.7V144.5H99.2ZM162.225 136.799L162.227 136.801C171.652 143.802 182.625 147.299 195.125 147.299C213.675 147.299 228.424 140.097 239.322 125.703C250.331 111.472 255.825 94.228 255.825 73.999C255.825 53.771 250.331 36.527 239.322 22.295C228.424 7.90101 213.675 0.700012 195.125 0.700012C182.892 0.700012 171.989 4.13001 162.433 10.994L162.431 10.996C153.02 17.852 145.899 26.926 141.065 38.203V38.205C136.502 48.941 134.225 60.875 134.225 74C134.225 87.26 136.569 99.329 141.266 110.199L141.268 110.203C146.101 121.078 153.087 129.947 162.225 136.8V136.799ZM217.504 106.329L217.503 106.331C211.985 115.001 204.537 119.299 195.125 119.299C185.848 119.299 178.33 115.003 172.543 106.325C166.873 97.488 164.025 86.721 164.025 73.999C164.025 61.139 166.875 50.442 172.542 41.875L172.546 41.869C178.199 33.061 185.716 28.699 195.125 28.699C204.534 28.699 211.982 33.06 217.501 41.865L217.508 41.875C223.175 50.442 226.025 61.14 226.025 73.999C226.025 86.723 223.176 97.492 217.504 106.329ZM368.341 144.5H368.841V109.499H389.441V85.299H368.841V3.50002H339.706L339.556 3.68102L269.756 87.881L269.641 88.02V109.5H339.841V144.5H368.341ZM70.7 85.299H33.875L70.7 41.57V85.3V85.299ZM339.841 85.299H303.015L339.841 41.569V85.3V85.299Z" stroke="white"/>
                        </g>
                    </svg>

                </ErrorCode>
                <NotFound>{this.props.t('test.notFound')}</NotFound>
            </VerticalCenter>
        </Wrapper>;
    }
})
