import React from 'react';
import { assoc, dissoc, isEmpty, isNil, range } from 'ramda';
import { isMobile } from 'react-device-detect';
import cx from 'classnames';
import styled from 'styled-components';

import withFieldWrapper from '../withFieldWrapper';

const Items = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px;
`;

const NPS = props => {
    const { input, questions } = props;
    const options = range(0, 11);
    const value = input.value || {};
    const onChange = (value, index) => {
        const current = input.value || {};
        const values = !isNil(value) ? assoc(index, value, current) : dissoc(index, current);

        props.onChange(isEmpty(values) ? null : values);
    };

    return (questions || []).map((question, index) =>
        <div key={`question-${index}`}>
            <div>{ question }</div>
            { isMobile ?
                <select
                    onChange={e => onChange(Number(e.target.value), index)}
                    value={value[index]}>
                    <option></option>
                    { options.map(option =>
                        <option key={`question-${index}-option-${option}`} value={option}>
                            { option }
                        </option>
                    )}
                </select> :
                <Items>
                    { options.map(option =>
                        <button
                            className={cx('nps-button', { 'nps-button-active': value[index] === option })}
                            type='button'
                            key={`question-${index}-option-${option}`}
                            onClick={() => onChange(option, index)}>
                            { option }
                        </button>
                    )}
                </Items>
            }
        </div>
    );
}

export default withFieldWrapper(NPS);
