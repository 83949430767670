import React from 'react';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';

const Container = styled.div`
    background: #fff;
    @media (max-width: 767px) {
        border: none;
    }
`;

const Title = styled.h3`
    padding: 15px;
    font-size: 1.3em;
    line-height: 1.5em;
    font-weight: normal;
    background-color: ${({ bg }) => bg || '#2b3d4f'};
    color: ${({ color }) => color || '#fff'};
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        margin: 0 -10px;
        padding: 15px 20px;
    }
`;

const Text = styled.h4`
    font-size: 24px;
    padding: 25px;
    white-space: pre-wrap;
    @media (max-width: 767px) {
            padding: 5px;
    }
`;

const Content = styled.div`
    padding: 50px;
    text-align: center;
    font-size: 1.2em;
    border: 3px solid #2c3d4e;
    svg {
        margin: 15px 0;
    }
    @media (max-width: 767px) {
        border: 0;
    }
`;

const Thanks = ({ language, respondent }) => {
    const finishTitleTranslation = pathOr('Опрос завершен', ['publicSurvey', 'translation', 'pageElements', 'finishTitle'], respondent);
    const finishTextTranslation = pathOr('Спасибо за участие!', ['publicSurvey', 'translation', 'pageElements', 'finishText'], respondent);

    return <Container className='finish'>
        <Title>
            { language ? pathOr(finishTitleTranslation, ['publicSurvey', 'translation', language, 'finishTitle'], respondent) : finishTitleTranslation }
        </Title>
        <Content>
            <Text>
                { language ? pathOr(finishTextTranslation, ['publicSurvey', 'translation', language, 'finishText'], respondent) : finishTextTranslation }
            </Text>
            <CheckCircle size={100} color='#75c464' />
        </Content>
    </Container>;
}

export default Thanks;
