import qs from 'qs';

export const isDemo = location => {
    const { demo } = qs.parse(location.search, { ignoreQueryPrefix: true });

    return demo === '1';
}

export const getToken = location => {
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

    return token;
}

