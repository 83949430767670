import React, { useRef, useState } from 'react';
import { pathEq, assocPath, pathOr } from 'ramda';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import cx from 'classnames';

import withFieldWrapper from '../withFieldWrapper';
import { isComment } from '../../../../utils/fields';

const CommentMobileWrapper = styled.div`
    text-align: center;
    margin-bottom: 15px;
`;
const ThFirst = styled.th`
    @media (min-width: 813px) {
        min-width: 450px;
        width: auto;
    }
`;
const ThLabel = styled.th`
    @media (min-width: 813px) {
        min-width: 50px;
        width: auto;
        max-width: 150px;
    }
`;

const LabelRadio = styled.label`
    position: relative;
    input {
        position: absolute;
        margin-left: -15px;
        opacity: 0;
        &:checked + .checked-box {
            box-shadow: rgb(43 61 79) 0px 0px 0px 2px inset, rgb(255 255 255) 0px 0px 0px 6px inset, rgb(43 61 79) 0px 0px 0px 12px inset, transparent 0px 0px 0px 24px;
        }
    }
    .checked-box {
        cursor: pointer;
        display: block;
        margin-right: 10px;
        float: left;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #ededed;
        border: 0;
        box-shadow: none;
        transition: box-shadow 280ms cubic-bezier(0.82, 0.14, 0.44, 0.41) 0s;
    }
`;

const Comment = props => {
    const [opened, setOpened] = useState(false);
    const textarea = useRef();

    const open = () => {
        setOpened(true);
        setTimeout(() => textarea.current.focus());
    }

    const { value, disabled, commentTranslation } = props;
    const onChange = e => props.onChange(e.target.value);

    return opened ?
        <textarea
            ref={textarea}
            value={value}
            disabled={disabled}
            onChange={onChange} /> :
        <span className='comment' onClick={open}>{ commentTranslation }</span>;
}

const Single = props => {
    const { questions, input, scale, language, translatedQuestions, disabled, respondent } = props;
    const commentTranslation = pathOr('Комментарий', ['translation', language, 'commentButton'], respondent.publicProject);
    const onChange = (value, index) => {
        const values = assocPath([index], value, input.value || []);
        props.onChange(values);
    }

    return isMobile ?
        <div>
            { questions.map((question, index) =>
                isComment(question) ?
                    <CommentMobileWrapper key={`comment-${index}`}>
                        <Comment
                            value={(input.value || [])[index]}
                            disabled={disabled}
                            onChange={value => onChange(value, index)}
                            commentTranslation={commentTranslation} />
                    </CommentMobileWrapper> :
                    <div key={`question-${index}`} className='mb-4'>
                        <div dangerouslySetInnerHTML={{ __html: language && translatedQuestions[index] ? translatedQuestions[index] : question }} />
                        <select
                            className='mt-2'
                            disabled={disabled}
                            onChange={e => onChange(Number(e.target.value), index)} value={(input.value || [])[index]}>
                            <option></option>
                            { scale.map((variant, i) =>
                                <option key={`variant-${i}`} value={i}>
                                    { variant }
                                </option>
                            )}
                        </select>
                    </div>
            )}
        </div> :
        <table>
            <thead>
                <tr>
                    <ThFirst/>
                    { scale.map((variant, index) =>
                        <ThLabel key={`variant-${index}`}>{ variant }</ThLabel>
                    )}
                </tr>
            </thead>
            <tbody>
                { questions.map((question, index) =>
                    <tr key={`question-${index}`}>
                        <td
                            className={cx({ 'answer-text-no-comment': isComment(questions[index + 1]) }) + 'answer-text'}
                            dangerouslySetInnerHTML={{ __html: isComment(question) ? '' : (language && translatedQuestions[index] ? translatedQuestions[index] : question) }} />
                        { isComment(question) ?
                            <td colSpan={scale.length} className='text-center'>
                                <Comment
                                    value={(input.value || [])[index]}
                                    disabled={disabled}
                                    onChange={value => onChange(value, index)}
                                    commentTranslation={commentTranslation} />
                            </td> :
                            scale.map((variant, i) =>
                                <td
                                    className={ isComment(questions[index + 1]) ? 'text-center' : 'answer-no-comment' }
                                    key={`variant-${index}-${i}`}>
                                    <LabelRadio className='radio' id={`answer-${index}-${i}`}>
                                        <input
                                            type='radio'
                                            checked={pathEq([index], i, input.value || [])}
                                            value={i}
                                            disabled={disabled}
                                            onChange={e => onChange(Number(e.target.value), index)} />
                                        <span className="checked-box" />
                                    </LabelRadio>
                                </td>
                            )
                        }
                    </tr>
                )}
            </tbody>
        </table>;
}

export default withFieldWrapper(Single);
