import { pathOr } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import withQuestionFieldWrapper from '../withQuestionFieldWrapper';

export const StyledTextarea = styled.textarea`
    border-width: 2px;
    width: 100%;
    display: block;
    padding: 0.5rem;
    border-color: #eee;
    max-width: 100%;
    min-height: 80px;
    &:focus-visible,&:focus {
        border-radius: 0;
        border-color: #2b3d4f;
        outline: none
    }
`;

const Textarea = props => {
    const { value, disabled, language, respondent } = props;
    const placeholder = pathOr('Ответ', ['translation', language, 'placeholder'], respondent.publicProject);
    const onChange = e => {
        const { value } = e.target;
        props.onChange(value);
    };

    return <StyledTextarea
        value={value || ''}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange} />;
}

export default withQuestionFieldWrapper(Textarea);
