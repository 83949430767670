import React, { Component, Fragment } from 'react';
import { Form } from 'react-final-form';
import { assocPath, has, equals, path } from 'ramda';
import { withAsyncHandlers } from 'react-async-client';
import arrayMutators from 'final-form-arrays';
import styled from 'styled-components';

import InitialValuesContext from '../contexts/InitialValuesContext';
import ERRORS, { DEFAULT_ERROR } from '../../constants/errors';

const Alert = styled.div`
    padding: 8px 15px;
    margin: 15px;
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    border-radius: 4px;
`;

export default (WrappedComponent, formOptions = {}) => {
    const enableReinitialize = has('enableReinitialize', formOptions) ? formOptions.enableReinitialize : true;

    const FormWithHandlers = withAsyncHandlers(({ formAction }) => {
        return (formAction && formAction.dispatch && ({
            formAction: {
                successHandler: props => {
                    props.onSubmitSuccess && props.onSubmitSuccess(props);
                },
                errorHandler: props => {
                    props.onSubmitFail && props.onSubmitFail(props);
                },
            }
        })) || {};
    })(WrappedComponent);

    return class FormWrapper extends Component {
        constructor(props) {
            super(props);

            this.state = {
                initialValues: this.getInitialValues(props)
            };
        }

        componentDidUpdate(prev) {
            const initialValues = this.getInitialValues();

            if (!equals(initialValues, this.getInitialValues(prev)) && enableReinitialize) {
                this.setState({ initialValues });
            }
        }

        getInitialValues = (props = this.props) => {
            return formOptions.mapPropsToValues ? formOptions.mapPropsToValues(props) : {};
        }

        isSubmitting = () => {
            const { formAction } = this.props;

            return formAction && formAction.dispatch && formAction.meta.pending;
        }

        onSubmit = values => {
            const { formAction } = this.props;
            const mapBeforeSubmit = this.props.mapBeforeSubmit || formOptions.mapBeforeSubmit;
            const dispatch = formAction.dispatch || formAction;

            if (!this.isSubmitting()) {
                dispatch(mapBeforeSubmit ? mapBeforeSubmit(values, this.props) : values, this.props);
            }
        }

        validate = values => {
            const schema = this.getValidationSchema();

            if (!schema) {
                return {};
            }

            try {
                schema.validateSync(values, { abortEarly: false });
            } catch (e) {
                return e.inner.reduce((errors, error) => {
                    const path = error.path.split(/\.|\].|\[/).map(p => isNaN(Number(p)) ? p : Number(p));
                    return assocPath(path, error.message, errors);
                }, {});
            }
        }

        getValidationSchema = () => {
            const schema = formOptions.validationSchema;

            return schema ? (typeof schema === 'function' ? schema(this.props) : schema) : null;
        }

        getError = () => {
            const error = path(['meta', 'error', 'data', 'error_description'], this.props.formAction);
            const networkError = path(['meta', 'error', 'origin', 'message'], this.props.formAction);

            return error || networkError;
        }

        renderError(error) {
            const status = path(['meta', 'error', 'status'], this.props.formAction);
            const message = status < 500 ? (ERRORS[error] || this.props.defaultError) : (ERRORS[error] || DEFAULT_ERROR);

            return message && <Alert>{ message }</Alert>;
        }

        render() {
            const error = this.getError();

            return <InitialValuesContext.Provider value={this.state.initialValues}>
                <Form
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    subscription={{ submitting: true, invalid: true, submitFailed: true, error: true }}
                    initialValues={this.state.initialValues}
                    mutators={arrayMutators}
                    render={props =>
                        <Fragment>
                            { error && this.renderError(error) }
                            <form onSubmit={props.handleSubmit} noValidate>
                                <FormWithHandlers {...this.props} {...props} />
                            </form>
                        </Fragment>
                    } />
            </InitialValuesContext.Provider>;
        }
    }
}
