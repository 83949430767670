import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: inline-flex;
    align-items: baseline;
    margin-right: 2px;
`;

const Bar = styled.div`
    background: ${({ level, index }) => level === 3 ? '#d9534f' : (level === 2 && index !== 2) ? '#ED9D29' : level === 1 && !index ? '#5cb85c' : '#B4B4B4'};
    width: 3px;
    margin-right: 2px;
    height: ${({ index }) => index === 2 ? 15 : index === 1 ? 11 : 6}px;
`;

const Complexity = ({ level }) =>
    <Wrapper>
        <Bar index={0} level={level} />
        <Bar index={1} level={level} />
        <Bar index={2} level={level} />
    </Wrapper>;

export default Complexity;
