import React, { Component } from 'react';
import { pathOr, assoc, dissoc, isEmpty, filter, isNil, omit, uniqBy, prop, findIndex, equals } from 'ramda';

import withFieldWrapper from '../withFieldWrapper';
import { StyledTextarea } from './Textarea';

class Matrix extends Component {
    onChange = (value, index) => {
        const current = this.props.input.value || {};
        const values = !isNil(value) ? assoc(index, value, current) : dissoc(index, current);

        this.props.onChange(isEmpty(values) ? null : values);
    }

    getData = () => {
        const { translatedQuestions, language } = this.props;
        const questions = this.props.questions.map((item, index) => {
            const question = language && translatedQuestions[index] ? translatedQuestions[index] : item;
            const paths = question.split('|');

            return paths.length > 1 ? ({ type: 'matrix', x: paths[0], y: paths[1], index }) : ({ comment: true, text: question, index });
        });
        const x = uniqBy(prop('x'), filter(item => !isNil(item.x), questions.map(item => omit(['y'], item))));
        const y = uniqBy(prop('y'), filter(item => !isNil(item.y), questions.map(item => omit(['x'], item))));
        const comments = filter(({ comment }) => !!comment, questions);

        return { questions: filter(({ comment }) => !comment, questions), comments, x, y };
    }

    render() {
        const { input: { value }, disabled, scale, questions } = this.props;
        const data = this.getData();

        return <div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        { data.x.map((item, index) => <th key={`th-${index}`}>{ item.x }</th>) }
                    </tr>
                </thead>
                <tbody>
                    { data.y.map((item, index) => <tr key={`row-${index}`}>
                        <td>{ item.y }</td>
                        { data.x.map((xItem, i) => {
                            const questionIndex = findIndex(equals(`${ xItem.x }|${ item.y }`), questions);

                            return <td key={`td-${index}-${i}`}>
                                {questionIndex}
                                <select
                                    className='mt-2'
                                    disabled={disabled}
                                    onChange={e => this.onChange(Number(e.target.value), questionIndex)}
                                    value={value[questionIndex]}>
                                    <option></option>
                                    { scale.map((variant, idx) =>
                                        <option key={`variant-${idx}`} value={idx}>
                                            { variant }
                                        </option>
                                    )}
                                </select>
                            </td>;
                        })}
                    </tr>)}
                </tbody>
            </table>
            { data.comments.map((question, index) =>
                <div className='my-2' key={`question-${index}`}>
                    <div className='mb-1' dangerouslySetInnerHTML={{ __html: question.text }} />
                    <StyledTextarea
                        value={pathOr('', [question.index], value)}
                        disabled={disabled}
                        onChange={e => this.onChange(e.target.value, question.index)} />
                </div>
            )}
        </div>;
    }
}

export default withFieldWrapper(Matrix);
