import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { pathOr, path, contains, findIndex, drop, equals, all, find, propEq } from 'ramda';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Timer } from '@styled-icons/material/Timer';
import { withStateHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';

import Wrapper from '../Wrapper';
import TestFinished from './TestFinished';
import StartTest from './StartTest';
import Question from './Question';
import TestPaused from './TestPaused';
import { pushRollbarError } from '../../utils/rollbar';
import Button from '../Button';
import withTimer from '../hocs/withTimer';
import ExecutionTime from './ExecutionTime';

const TimerWrapper = styled.div`
    font-size: 18px;
    display: flex;
    align-items: center;
    svg {
        margin-right: 7px;
    }
`;

const ErrorWrapper = styled.div`
    text-align: center;
    margin-top: 15px;
    button {
        margin-top: 15px;
    }
`;

const ErrorTitle = styled.h1`
    font-weight: 300;
    text-shadow: 1px 6px 6px #868686;
    font-size: 19.4em;
    line-height: 0.8em;
    margin-bottom: 75px;
`;

const ErrorDescription = styled.h2`
    font-weight: 300;
    font-size: 2.4em;
`;

const VerticalCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 130px);
`;

class Main extends Component {
    render() {
        const {
            getResponse: { data, refresh },
            postResponsePdaAction,
            postResponseStartAction,
            responseType,
            postResponseStartFinishAction,
            status,
            setStat,
            stat,
            currentQuestion,
            paused,
            startTimer,
            timer
        } = this.props;
        const title = pathOr(data.title, ['infoScheme', 'title'], data);

        return status === 'finished' ?
            <TestFinished title={title} data={data} /> :
            paused ? <TestPaused title={title} respondent={data} /> :
                <StartTest
                    respondent={data}
                    title={title}
                    refresh={refresh}
                    postResponsePdaAction={postResponsePdaAction}
                    postResponseStartAction={postResponseStartAction}
                    responseType={responseType}
                    postResponseStartFinishAction={postResponseStartFinishAction}
                    setStat={setStat}
                    status={status}
                    stat={stat}
                    currentQuestion={currentQuestion}
                    startTimer={startTimer}
                    timer={timer} />;
    }
}

class QuestionRoute extends Component {
    getNextQuestion = number => findIndex(x => !contains(x, ['answered', 'expired']), drop(Number(number) + 1, this.props.stat || []));

    render() {
        const {
            getResponse: { meta, data },
            match,
            responseType,
            status,
            stat,
        } = this.props;
        const number = Number(match.params.num);
        const next = this.getNextQuestion(number);

        return meta.pending && !meta.lastSucceedAt ? null :
            status && status !== 'finished' && number < data.questionsCount ? (
                ((equals(stat[number], 'answered') && !data.reAnswerQuestionAllowed) || equals(stat[number], 'expired')) ?
                (next > -1 ? <Redirect to={`/${responseType}/${match.params.id}/question/${next + number + 1}`} /> : <Redirect to={`/${responseType}/${match.params.id}`} />) :
                <Question
                    {...this.props}
                    page={find(propEq('questionNumber', number), data.pages || [])}
                />
            ) :
            meta.success && <Redirect to={`/${responseType}/${match.params.id}`} />
    }
}

class Response extends Component {
    state = {
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    componentDidUpdate() {
        if (this.props.status === 'finished' && this.props.timer) {
            this.props.stopTimer();
        }
    }

    reloadPage = () => this.props.history.push(`/refresh${this.props.location.pathname}`);

    renderTimer = () => {
        return <TimerWrapper>
            <Timer size={20} /> <ExecutionTime executionTime={this.props.seconds} timeLimited />
        </TimerWrapper>;
    }

    render() {
        const {
            getResponse,
            getResponse: { meta, data },
            postResponseStartAction,
            match,
            responseType,
            postResponseFinish,
            postResponseStartFinishAction,
            timer,
            status,
            setStat,
            stat,
            currentQuestion,
            paused,
            startTimer,
            t
        } = this.props;
        const infoScheme = pathOr({}, ['infoScheme'], data);

        if (this.state.error) {
            return <Wrapper>
                <ErrorWrapper>
                    <p>{t('test.error')}</p>
                    <Button onClick={this.reloadPage}>{t('test.reload')}</Button>
                </ErrorWrapper>
            </Wrapper>;
        }

        if (meta.error) {
            return (
                <Wrapper>
                    <VerticalCenter>
                        <ErrorTitle>{path(['status'], meta.error)}</ErrorTitle>
                        <ErrorDescription>{t('test.notFound')}</ErrorDescription>
                    </VerticalCenter>
                </Wrapper>
            );
        }

        return <Wrapper
            headerName={timer ? this.renderTimer() : infoScheme.showRespondentName && data.respondentName}
            designScheme={path(['designScheme'], infoScheme)}
            pending={meta.pending && !meta.lastSucceedAt}>
            <Switch>
                <Route path={`/${responseType}/:id`} exact render={props =>
                    <Main {...props}
                        getResponse={getResponse}
                        postResponseStartAction={postResponseStartAction}
                        responseType={responseType}
                        postResponseFinish={postResponseFinish}
                        postResponseStartFinishAction={postResponseStartFinishAction}
                        status={status}
                        stat={stat}
                        setStat={setStat}
                        currentQuestion={currentQuestion}
                        paused={paused}
                        startTimer={startTimer}
                        timer={timer} />
                    } />
                <Route path={`/${responseType}/:id/question/:num`} render={props => <QuestionRoute {...this.props} {...props} />} />
                <Route render={() => <Redirect to={`/${responseType}/${match.params.id}`} />} />
            </Switch>
        </Wrapper>;
    }
}

export default withStateHandlers({ stat: [], status: '', currentQuestion: 0, paused: false }, {
    setStat: prev => (stat, status) => ({ stat, status: status || prev.status }),
    setCurrentQuestion: () => currentQuestion => ({ currentQuestion }),
    setPaused: () => paused => ({ paused })
})(
    withTranslation()(withTimer(
        asyncConnect(({ getResponseAction, postResponseFinishAction, paused, setPaused }) => ({
            getResponse: getResponseAction
                .withPayload(({ match }) => match.params.id)
                .withSuccessHandler(({ getResponse: { data }, responseType, postResponseFinish, startTimer, timer, setStat }) => {
                    const isAudit = !!((responseType === 'audits') || data.auditComment || data.auditEnabled);
                    const stat = data.stat || [];
                    const finished = data.status !== 'finished' && stat && stat.length && (all(equals('answered'), stat) || (data.timeLeft < 1 && data.timeLimited && data.timeLimitedBy === 'test'));

                    if (!isAudit && finished) {
                        postResponseFinish.dispatch(data.id);
                        return;
                    }

                    paused && setPaused(false);
                    setStat(stat, data.status);

                    if (!isAudit && data.timeLimited && data.timeLimitedBy === 'test' && data.timeLeft) {
                        !timer && startTimer(data.timeLeft);
                    }
                })
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
            postResponseFinish: postResponseFinishAction
                .withSuccessHandler(({ isAudit, history, setStat, stat }) => {
                    setStat(stat, 'finished');
                    isAudit && history.push('/audits/success');
                })
                .withOptions({ resetOnUnmount: true })
        }))(Response)
    ))
);
