import axios from 'axios';
import { isEmpty } from 'ramda';

import * as urls from '../../constants/urls';

export const getTestsHandler = ({ payload }) => axios.get(urls.TESTS.stringify({ code: payload.code, company: payload.company }));
export const getTestsPdaHandler = ({ payload }) => axios.get(urls.TESTS_PDA.stringify({ code: payload.code, company: payload.company }));
export const postTestsPdaHandler = ({ payload }) => axios.post(urls.TESTS_PDA.stringify({ code: payload.code, company: payload.company }), {
    agreement: true,
    contract: true,
    politics: true,
});
export const getRespondentHandler = ({ payload }) => axios.get(urls.RESPONDENT.stringify({ id: payload }));
export const postRespondentPdaHandler = ({ payload: { id } }) => axios.post(urls.RESPONDENT_PDA.stringify({ id }), {
    agreement: true,
    contract: true,
    politics: true,
});
export const postRespondentStartHandler = async (action) => {
    const { payload: { id, ...data } } = action;
    await postRespondentPdaHandler(action);
    return axios.post(urls.RESPONDENT_START.stringify({ id }), isEmpty(data) ? null : data);
};
export const postRespondentFinishHandler = ({ payload }) => axios.post(urls.RESPONDENT_FINISH.stringify({ id: payload }));
export const getRespondentQuestionHandler = ({ payload }) => axios.get(urls.RESPONDENT_QUESTION.stringify({ id: payload.id, num: payload.num }), {
    params: {
        relations: []
    }
});

export const postRespondentStartFinishHandler = ({ payload }) =>
    payload.status === 'started' ?
        postRespondentFinishHandler({ payload: payload.id }) :
        postRespondentStartHandler({ payload }).then(() => postRespondentFinishHandler({ payload: payload.id }));

export const postRespondentAnswerHandler = ({ payload: { id, num, finishAfterAnswer, isExpert, ...data }}) => isExpert ?
    (finishAfterAnswer ?
        axios.post(urls.RESPONDENT_ANSWER.stringify({ id, num }), data).then(() => axios.post(urls.RESPONDENT_COMMENT.stringify({ id, num }), data)).then(() => axios.post(urls.RESPONDENT_FINISH.stringify({ id }))) :
        axios.post(urls.RESPONDENT_ANSWER.stringify({ id, num }), data).then(() => axios.post(urls.RESPONDENT_COMMENT.stringify({ id, num }), data))).then(() => data) :
    (finishAfterAnswer ?
        axios.post(urls.RESPONDENT_ANSWER.stringify({ id, num }), data).then(() => axios.post(urls.RESPONDENT_FINISH.stringify({ id }))) :
        axios.post(urls.RESPONDENT_ANSWER.stringify({ id, num }), data)).then(() => data);

export const getAuditHandler = ({ payload }) => axios.get(urls.AUDIT.stringify({ id: payload }));
export const postAuditStartHandler = ({ payload: { id, ...data }}) => axios.post(urls.AUDIT_START.stringify({ id }), isEmpty(data) ? null : data);
export const postAuditFinishHandler = ({ payload }) => axios.post(urls.AUDIT_FINISH.stringify({ id: payload }));
export const getAuditQuestionHandler = ({ payload }) => axios.get(urls.AUDIT_QUESTION.stringify({ id: payload.id, num: payload.num }));
export const postAuditStartFinishHandler = ({ payload }) =>
    payload.status === 'started' ?
        postAuditFinishHandler({ payload: payload.id }) :
        postAuditStartHandler({ payload }).then(() =>  postAuditFinishHandler({ payload: payload.id }));
export const postAuditAnswerHandler = ({ payload: { id, num, finishAfterAnswer, ...data }}) => finishAfterAnswer ?
    axios.post(urls.AUDIT_ANSWER.stringify({ id, num }), data).then(() => axios.post(urls.AUDIT_FINISH.stringify({ id }))) :
    axios.post(urls.AUDIT_ANSWER.stringify({ id, num }), data);

export const getSurveyHandler = ({ payload }) => axios.get(urls.SURVEY.stringify({ id: payload }));
export const getSurveyRespondentHandler = ({ payload }) => axios.get(urls.SURVEY_RESPONDENT.stringify({ id: payload.id }), {
    headers: {
        'Authorization': payload.token ? `Bearer ${payload.token}` : undefined,
    },
});
export const postSurveyRespondentPdaHandler = ({ payload: { id, data }}) => axios.post(urls.SURVEY_RESPONDENT_PDA.stringify({ id }), isEmpty(data) ? null : data);
export const postSurveyRespondentStartHandler = ({ payload: { id, data }}) => axios.post(urls.SURVEY_RESPONDENT_START.stringify({ id }), isEmpty(data) ? null : data);
export const postSurveyRespondentFinishHandler = ({ payload: { id, data } }) => axios.post(urls.SURVEY_RESPONDENT_FINISH.stringify({ id }), data);
export const getSurveyRespondentQuestionHandler = ({ payload }) => axios.get(urls.SURVEY_RESPONDENT_QUESTION.stringify({ id: payload.id, num: payload.num }));
export const postSurveyRespondentStartFinishHandler = ({ payload }) =>
    payload.status === 'started' ?
        postSurveyRespondentFinishHandler({ payload: payload.id }) :
        postSurveyRespondentStartHandler({ payload }).then(() => postSurveyRespondentFinishHandler({ payload: payload.id }));
export const putSurveyRespondentQuestionHandler = ({ payload }) => axios.put(urls.SURVEY_RESPONDENT_QUESTION.stringify({ id: payload.id, num: payload.num }), payload.data);

export const getRegionsHandler = () => axios.get(urls.REGIONS.stringify());
export const getSpecialitiesHandler = () => axios.get(urls.SPECIALITIES.stringify());

export const getCompanyHandler = ({ payload }) => axios.get(urls.COMPANIES.stringify({ id: payload }));

export const getRespondentReportHandler = ({ payload }) => axios.get(urls.RESPONDENT_REPORT.stringify({ id: payload }), {
    params: {
        relations: ['employee', 'employee.businessUnit', 'testSuite', 'testSuite.questions', 'testSuite.questions.category', 'testSuite.questions.category.parent', 'testSuite.questions.category.parent.parent']
    }
});
