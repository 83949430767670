import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import NoMatch from './NoMatch';
import Tests from './Tests';
import Respondent from './Respondent';
import SurveyRespondent from './SurveyRespondent';
import Audit from './Audit';
import CompanyPage from './CompanyPage';
import Route from './Route';
import Refresh from './Refresh';
import AuditSuccess from './AuditSuccess';
import RespondentsReport from './RespondentsReport';

export default () =>
    <Switch>
        <Refresh path='/refresh' />
        <Route path='/survey/respondents/:id' component={({ match: { params: { id } } }) => <Redirect to={`/survey_respondents/${id}`} />} />
        <Route path='/survey_respondents/:id' component={SurveyRespondent} />
        <Route path='/respondents/:id/report' exact component={RespondentsReport} />
        <Route path='/respondents/:id' component={Respondent} />
        <Route path='/audits/success' component={AuditSuccess} />
        <Route path='/audits/:id' component={Audit} />
        <Route path='/tests/:company/:code' component={Tests} />
        <Route path='/:company' component={CompanyPage} />
        <Route component={NoMatch} />
    </Switch>;
