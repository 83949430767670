import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { filter, find, propEq, contains, isNil, without, append, includes } from 'ramda';
import styled from 'styled-components';

import withFieldWrapper from '../withFieldWrapper';
import { reorder, move } from '../../../../utils/dnd';
import { usePrevious } from '../../../../utils/hooks';

const DragWrap = styled.div`
    display: flex;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        margin: -14px;
        background-color: #fff;
        border: 2px solid #eeeeee;
        background-image: url("data:image/svg+xml,%3Csvg style='enable-background:new 0 0 50 50;' version='1.1' viewBox='0 0 50 50' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Layer_1'%3E%3Cpath d='M9.465,37.95l8.242-8.243l-1.414-1.414l-8.242,8.242C7.105,37.48,5.85,38,4.515,38H1v2h3.515 C6.384,40,8.142,39.272,9.465,37.95z'/%3E%3Cpath d='M32.535,12.05l-8.242,8.243l1.414,1.414l8.242-8.242C34.895,12.52,36.15,12,37.485,12h6.101l-4.293,4.293l1.414,1.414 L47.414,11l-6.707-6.707l-1.414,1.414L43.586,10h-6.101C35.616,10,33.858,10.728,32.535,12.05z'/%3E%3Cpath d='M8.051,13.464L32.535,37.95c1.323,1.322,3.081,2.05,4.95,2.05h6.101l-4.293,4.293l1.414,1.414L47.414,39l-6.707-6.707 l-1.414,1.414L43.586,38h-6.101c-1.335,0-2.591-0.52-3.536-1.464L9.465,12.05C8.142,10.728,6.384,10,4.515,10H1v2h3.515 C5.85,12,7.105,12.52,8.051,13.464z'/%3E%3C/g%3E%3Cg/%3E%3C/svg%3E");
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 767px) {
            width: 16px;
            height: 16px;
            margin: -8px;
            background-size: 14px;
            border: 1px solid #eeeeee;
        }
    }
`;

const DragCol = styled.div`
    width: 50%;
    padding: 0.75rem 1.25rem;
    border: 2px solid #eee;

    &:last-child {
        border-left-width: 0;
    }
    @media (max-width: 767px) {
        padding: 0.2rem 0.5rem;
    }
`;

const DraggableBox = styled(Draggable)`
    padding: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border: 1px dashed #eee;
    background: #f3f3f3;
`;

const DragBox = styled.div`
    padding: 0.5rem 24px 0.5rem 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border: 1px dashed #eee;
    background: #f3f3f3;
    position: relative;
    font-weight: bold;
    @media (max-width: 767px) {
        font-size: 13px;
        padding: 0.5rem;
    }
    @media (min-width: 768px) {
        &:before {
            content: '';
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTkgMTBDMTkgMTIuMjA5MSAxNy4yMDkxIDE0IDE1IDE0QzEyLjc5MDkgMTQgMTEgMTIuMjA5MSAxMSAxMEMxMSA3Ljc5MDg2IDEyLjc5MDkgNiAxNSA2QzE3LjIwOTEgNiAxOSA3Ljc5MDg2IDE5IDEwWk0xNSAyOEMxNy4yMDkxIDI4IDE5IDI2LjIwOTEgMTkgMjRDMTkgMjEuNzkwOSAxNy4yMDkxIDIwIDE1IDIwQzEyLjc5MDkgMjAgMTEgMjEuNzkwOSAxMSAyNEMxMSAyNi4yMDkxIDEyLjc5MDkgMjggMTUgMjhaTTE1IDQyQzE3LjIwOTEgNDIgMTkgNDAuMjA5MSAxOSAzOEMxOSAzNS43OTA5IDE3LjIwOTEgMzQgMTUgMzRDMTIuNzkwOSAzNCAxMSAzNS43OTA5IDExIDM4QzExIDQwLjIwOTEgMTIuNzkwOSA0MiAxNSA0MloiIGZpbGw9ImJsYWNrIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNyAxMEMzNyAxMi4yMDkxIDM1LjIwOTEgMTQgMzMgMTRDMzAuNzkwOSAxNCAyOSAxMi4yMDkxIDI5IDEwQzI5IDcuNzkwODYgMzAuNzkwOSA2IDMzIDZDMzUuMjA5MSA2IDM3IDcuNzkwODYgMzcgMTBaTTMzIDI4QzM1LjIwOTEgMjggMzcgMjYuMjA5MSAzNyAyNEMzNyAyMS43OTA5IDM1LjIwOTEgMjAgMzMgMjBDMzAuNzkwOSAyMCAyOSAyMS43OTA5IDI5IDI0QzI5IDI2LjIwOTEgMzAuNzkwOSAyOCAzMyAyOFpNMzMgNDJDMzUuMjA5MSA0MiAzNyA0MC4yMDkxIDM3IDM4QzM3IDM1Ljc5MDkgMzUuMjA5MSAzNCAzMyAzNEMzMC43OTA5IDM0IDI5IDM1Ljc5MDkgMjkgMzhDMjkgNDAuMjA5MSAzMC43OTA5IDQyIDMzIDQyWiIgZmlsbD0iYmxhY2siIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
            display: inline-block;
            width: 16px;
            height: 16px;
            background-size: 16px;
            position: absolute;
            right: 4px;
            opacity: .6;
        }
    }
`;

const Drag = props => {
    const [unselected, setUnselected] = useState([]);
    const [drag, setDrag] = useState(false);
    const prevValue = usePrevious(props.input.value);
    const getItems = useCallback(() => props.questions.map((name, index) => ({ name, id: index })), [props.questions]);
    const setUnselectedItems = useCallback(() => {
        const { value } = props.input;
        const items = getItems();

        setUnselected(filter(item => !includes(item.id, value || []), items));
    }, [props, getItems]);

    useEffect(() => setUnselectedItems(), [setUnselectedItems]);
    useEffect(() => {
        if (prevValue === '' && props.input.value) {
            setUnselectedItems();
        }
    }, [props.input.value, prevValue, setUnselectedItems]);

    const getSelectedItems = () => {
        const items = getItems();

        return filter(item => !!item, (props.input.value || []).map(i => find(propEq('id', i), items)));
    }

    const onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        setDrag(true);

        const lists = {
            left: unselected,
            right: getSelectedItems()
        };

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                lists[source.droppableId],
                source.index,
                destination.index
            );

            if (source.droppableId === 'left') {
                setUnselected(items);
            } else {
                props.onChange(items.map(({ id }) => id));
            }
        } else {
            const result = move(
                lists[source.droppableId],
                lists[destination.droppableId],
                source,
                destination
            );
            const selected = result.right.map(({ id }) => id);

            setUnselected(result.left);
            props.onChange(selected.length ? selected : null);
        }

        setDrag({ drag: false });
    }

    const checkItem = e => {
        const values = props.input.value || [];
        const value = Number(e.target.value);

        props.onChange(contains(value, values) ? without([value], values) : append(value, values));
    }

    const { language, translatedQuestions, options, input } = props;
    const selected = getSelectedItems();
    const items = getItems();
    const disabled = (!isNil(options.limitAnswers) && options.limitAnswers <= selected.length);

    return options.disableSort ?
        items.map(item =>
            <div key={item.id}>
                <label className='checkbox drag-field'>
                    <input
                        type='checkbox'
                        checked={includes(item.id, input.value || [])}
                        value={item.id}
                        onChange={checkItem}
                        disabled={disabled && !includes(item.id, input.value || [])} />
                    <span className='checked-box' /> <span>{ language && translatedQuestions[item.id] ? translatedQuestions[item.id] : item.name }</span>
                </label>
            </div>
        ) :
        <DragDropContext onDragEnd={onDragEnd}>
            <DragWrap className='drag-wrap'>
                <Droppable droppableId='left'>
                    { provided =>
                        <DragCol className='drag-col' ref={provided.innerRef}>
                            { unselected.map((item, index) =>
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id.toString()}
                                    index={index}>
                                    { provided =>
                                        <DragBox
                                            className='drag-box'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}>
                                            <div dangerouslySetInnerHTML={{ __html: language && translatedQuestions[item.id] ? translatedQuestions[item.id] : item.name }} />
                                        </DragBox>
                                    }
                                </Draggable>
                            )}
                            { provided.placeholder }
                        </DragCol>
                    }
                </Droppable>
                <Droppable droppableId='right' isDropDisabled={disabled || (drag && !isNil(options.limitAnswers) && options.limitAnswers <= selected.length - 1)}>
                    { provided =>
                        <DragCol ref={provided.innerRef} className='drag-col'>
                            { selected.map((item, index) =>
                                <DraggableBox
                                    className='drag-box'
                                    key={item.id}
                                    draggableId={item.id.toString()}
                                    index={index}>
                                    { provided =>
                                        <DragBox
                                            className='drag-box'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}>
                                            <div dangerouslySetInnerHTML={{ __html: language && translatedQuestions[item.id] ? translatedQuestions[item.id] : item.name }} />
                                        </DragBox>
                                    }
                                </DraggableBox>
                            )}
                            { provided.placeholder }
                        </DragCol>
                    }
                </Droppable>
            </DragWrap>
        </DragDropContext>;
}

export default withFieldWrapper(Drag);
