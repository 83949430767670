import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';
import { Clock } from '@styled-icons/fa-regular/Clock';
import { withTranslation } from 'react-i18next';

import DocumentHeader from './DocumentHeader';

const Container = styled.div`
    background: #fff;
    overflow: hidden;
    @media (max-width: 767px) {
        box-shadow: none;
        margin: 0 -10px;
        padding: 15px 0;
    }
`;

const Header = styled.h2`
    padding: 15px;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1.5em;
    font-weight: normal;
    color: #fff;
    background-color: #2b3d4f;
    @media (max-width: 767px) {
        padding: 15px 20px;
    }
`;

const Text = styled.h4`
    font-size: 24px;
    padding: 25px;
    @media (max-width: 767px) {
            padding: 5px;
    }
`;

const Content = styled.div`
    padding: 50px;
    text-align: center;
    font-size: 1.2em;
    border: 3px solid #2c3d4e;
    svg {
        margin: 15px 0;
    }
    @media (max-width: 767px) {
        border: 0;
    }
`;

export default withTranslation()(class TestFinished extends Component {
    render() {
        const { t, title, estimated, data } = this.props;
        const Icon = estimated ? Clock : CheckCircle;

        return <Container>
            <DocumentHeader title={`${t('test.thanks')} | ${title || ''}`} />
            <Header>{t('test.end')}</Header>
            <Content>
                <Text>{ estimated ? t('test.timeEnded') : (data.auditEnabled ? t('test.endedAudit') : t('test.ended')) }</Text>
                <Icon size={100} color='#75c464' />
                { data.showPublicTestsLink &&
                    <div>
                        <Link to={`/tests/${data.slug}/${data.code || data.email}`}>
                            {t('test.testsLink')}
                        </Link>
                    </div>
                }
            </Content>
        </Container>;
    }
});
