import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '/api';

export const TESTS = construct('/public/tests/:company/:code');
export const TESTS_PDA = construct('/public/tests/:company/:code/pda');
export const RESPONDENT = construct('/public/respondents/:id');
export const RESPONDENT_PDA = construct('/public/respondents/:id/pda');
export const RESPONDENT_START = construct('/public/respondents/:id/start');
export const RESPONDENT_FINISH = construct('/public/respondents/:id/finish');
export const RESPONDENT_QUESTION = construct('/public/respondents/:id/questions/:num');
export const RESPONDENT_ANSWER = construct('/public/respondents/:id/questions/:num/answer');
export const RESPONDENT_COMMENT = construct('/public/respondents/:id/questions/:num/comment');
export const RESPONDENT_REPORT = construct('/public/respondents/:id/report');
export const AUDIT = construct('/public/audits/:id');
export const AUDIT_START = construct('/public/audits/:id/start');
export const AUDIT_FINISH = construct('/public/audits/:id/finish');
export const AUDIT_QUESTION = construct('/public/audits/:id/questions/:num');
export const AUDIT_ANSWER = construct('/public/audits/:id/questions/:num/answer');
export const SURVEY = construct('/public/survey/:id');
export const SURVEY_RESPONDENT = construct('/public/survey/respondents/:id');
export const SURVEY_RESPONDENT_PDA = construct('/public/survey/respondents/:id/pda');
export const SURVEY_RESPONDENT_START = construct('/public/survey/respondents/:id/start');
export const SURVEY_RESPONDENT_FINISH = construct('/public/survey/respondents/:id/finish');
export const SURVEY_RESPONDENT_QUESTION = construct('/public/survey/respondents/:id/questions/:num');

export const REGIONS = construct('/public/regions');
export const SPECIALITIES = construct('/public/specialities');

export const COMPANIES = construct('/public/company/:id');
