import React from 'react';
import { head } from 'ramda';

const withQuestionFieldWrapper = WrappedComponent => props => {
    const { input: { value }} = props;
    const onChange = value => props.input.onChange(value ? [value] : null);

    return <WrappedComponent
        {...props}
        onChange={onChange}
        value={value ? head(value) : null} />;
}

export default withQuestionFieldWrapper;
