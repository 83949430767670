import form from './en/form.json';
import app from './en/app.json';
import company from './en/company.json';
import pda from './en/pda.json';
import test from './en/test.json';
import survey from './en/survey.json';
import audit from './en/audit.json';

export default {
    app,
    form,
    company,
    pda,
    test,
    survey,
    audit,
};
