import React, { useEffect, useState } from 'react';
import { pathOr, contains, path, fromPairs, all, addIndex, isNil } from 'ramda';
import { withAsyncActions } from 'react-async-client';
import { Form, Field } from 'react-final-form';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Twig from 'twig';

import Button from '../Button';
import Textarea from '../forms/survey/questionFormComponents/Textarea';
import Individual from '../forms/survey/questionFormComponents/Individual';
import Matrix from '../forms/survey/questionFormComponents/Matrix';
import Single from '../forms/survey/questionFormComponents/Single';
import Drag from '../forms/survey/questionFormComponents/Drag';
import Select from '../forms/survey/questionFormComponents/Select';
import SingleSelect from '../forms/survey/questionFormComponents/SingleSelect';
import Slider from '../forms/survey/questionFormComponents/Slider';
import NPS from '../forms/survey/questionFormComponents/NPS';
import { isDemo } from '../../utils/demo';
import { isComment } from '../../utils/fields';

const Title = styled.h2`
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 32px;
    position: relative;
    @media (max-width: 767px) {
        font-size: 28px;
    }
`;

const Container = styled.div`
    background: #fff;
    @media (max-width: 767px) {
        border: none;
    }
`;

const QuestionTitle = styled.div`
    padding: 15px;
    font-size: 1.3em;
    line-height: 1.5em;
    font-weight: normal;
    background-color: ${({ bg }) => bg || '#2b3d4f'};
    color: ${({ color }) => color || '#fff'};
    white-space: pre-wrap;
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        margin: 0 -10px;
        padding: 15px 20px;
    }
`;

const FieldContainer = styled.div`
    border-bottom: 4px solid #ededed;
    padding: 10px 15px 15px;
    @media (max-width: 767px) {
        padding: 15px 0 15px;
        border: 0;
        .form-item {
            .mb-4 {
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: normal;
            }
            select.mt-2 {
                margin-top: 8px;
                outline: 0;
                display: block;
                width: 100%;
                height: 40px;
                padding: 10px 16px;
                font-size: 16px;
                background-color: #ededed;
                border: 1px solid transparent;
                font-weight: bold;
                color: #2b3d4f;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L9 9L15 3' stroke='%232B3D4F' stroke-width='2'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 98% center;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
        }
    }
    label {
        font-size: 17px;
        display: inline-block;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    table {
        width: 100%;

        th {
            text-align: center;
            font-weight: normal;
            font-size: 13px;
            padding: 8px 4px 16px;
        }

        td {
            text-align: left;
            vertical-align: middle;
            padding: 8px 4px;
            font-weight: normal;
        }

        .answer-no-comment {
            padding: 4px 2px;
            text-align: center;
        }

        .answer-text {
            font-size: 16px;
        }

        .checked-box {
            margin: 0;
        }
    }
`;

const Footer = styled.div`
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
        padding: 10px 0;
        flex-wrap: wrap;
    }
`;

const QuestionFooter = styled.div`
    padding: 15px;
`;

const QuestionsPagination = styled.div`
    margin-bottom: 10px;
    text-align: center;
`;

const Help = styled.div`
    color: #a0a0a0;
    @media (max-width: 767px) {
        margin-bottom: 15px;
        font-size: 13px;
        order: 0;
    }
`;

const PaginationButton = styled.a`
    overflow: hidden;
    display: inline-block;
    border: 1px solid ${({ bg }) => bg || '#2b3d4f'};
    font-size: 12px;
    padding: 10px 15px;
    margin-right: 10px;
    position: relative;
    font-weight: bold;
    background: ${({ answered, bg }) => answered ? (bg || '#2b3d4f') : 'transparent'};
    color: ${({ answered }) => answered ? '#fff' : '#2b3d4f'};
    cursor: ${({ demo }) => demo ? 'pointer' : 'default'};
    &:hover {
        color: ${({ answered }) => answered ? '#fff' : '#2b3d4f'}
    }
    &:before {
        content: '';
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        background: ${({ current, bg }) => current ? (bg || '#2b3d4f') : 'transparent'};
        opacity: .2;
    }
    &:focus {
        outline: none;
    }
`;

const FIELDS = {
    single: Single,
    drag: Drag,
    textarea: Textarea,
    text: Textarea,
    individual: Individual,
    matrix: Matrix,
    slider: Slider,
    selects: Select,
    select: SingleSelect,
    nps: NPS
};

const QUESTIONS_HELP_TEXT = {
    'individual': {
        'ru': 'Выберите несколько правильных вариантов ответа.',
        'en': 'Choose multiple correct answers.',
    },
    'text': {
        'ru': 'Впишите ваш вариант ответа.',
        'en': 'Enter your answer.',
    },
    'textarea': {
        'ru': 'Впишите ваш вариант ответа.',
        'en': 'Enter your answer.',
    },
    'single': {
        'ru': 'Выберите один правильный вариант ответа.',
        'en': 'Choose one correct answer.',
    },
    'selects': {
        'ru': 'Выберите один правильный вариант ответа.',
        'en': 'Choose one correct answer.',
    },
    'select': {
        'ru': 'Выберите один правильный вариант ответа.',
        'en': 'Choose one correct answer.',
    },
    'drag': {
        'ru': 'Передвиньте предложенные варианты в нужной последовательности из левой области экрана в правую.',
        'en': 'Move the suggested options in the correct sequence from the left area of the screen to the right.',
    },
};

const CHOOSE_TEXT = 'Ниже представлен ряд утверждений, которые Вам предлагается оценить. Выберите насколько Вы согласны со следующими утверждениями';

const required = value => isNil(value) ? 'required' : undefined;

const VALIDATE = question => ({
    single: value => {
        return addIndex(all)((item, index) => isComment(item) ? true : !isNil(path([index], value)), question.questions) ? undefined : 'required';
    },
    drag: value => {
        const min = path(['options', 'minAnswers'], question);
        return !isNil(min) ? (value || []).length < min : required(value)
    },
    textarea: value => path(['options', 'allowEmpty'], question) ? undefined : required(value),
    individual: required,
    matrix: required,
    slider: required,
    select: required,
    nps: value => addIndex(all)((_, index) => !isNil(path([index], value)), question.questions) ? undefined : 'required'
});

const TITLE_FROM_QUESTIONS = ['textarea', 'individual'];
const TITLE_FROM_NAME = ['drag'];

const INITIAL_VALUES = {
    select: question => fromPairs(question.questions.map((_, index) => ([index, 0]))),
    nps: question => fromPairs(question.questions.map((_, index) => ([index, undefined]))),
    slider: question => question.questions.map(() => 50)
};

const Question = props => {
    const [initialValues, setInitialValues] = useState({});
    let hiddenQuestionTemplate = null;

    useEffect(() => {
        const { respondent, match: { params: { num }}, id, drivers } = props;
        const answers = pathOr([], ['response', 'answers'], respondent);
        const question = pathOr({}, ['publicSurvey', 'drivers', Number(num)], respondent);

        setInitialValues({
            answers: answers[num] || (INITIAL_VALUES[question.type] ? INITIAL_VALUES[question.type](question) : null),
            num,
            id,
            finish: Number(num) + 1 >= drivers.length
        });
    }, [props]);

    const { match: { params: { num }}, respondent, language, putSurveyRespondentQuestion, location, history, id, drivers } = props;

    const getQuestion = () => drivers[num];

    const getTranslation = () => pathOr({}, [num, 'translations', language], drivers);

    const onSubmit = values => {
        if (!isDemo(location)) {
            putSurveyRespondentQuestion.dispatch({
                id,
                num,
                data: {
                    ...values,
                    answers: !values.answers ? [''] : values.answers
                }
            });
        }
    }

    const getHiddenQuestionTemplate = () => {
        const hideQuestions = path(['options', 'hideQuestions'], getQuestion());

        if (!hideQuestions) {
            return null;
        }

        if (!hiddenQuestionTemplate) {
            hiddenQuestionTemplate = Twig.twig({
                data: hideQuestions
            });
        }

        return hiddenQuestionTemplate;
    }

    const onChangePage = index => {
        if (isDemo(location)) {
            history.push(`/survey_respondents/${id}/driver/${index}${location.search}`);
        }
    }

    const getScale = () => {
        const question = getQuestion();
        const translation = getTranslation();
        const questionScale = language ? pathOr([], ['scale'], translation) : pathOr([], ['scale'], question);
        const projectScale = language ? pathOr([], ['publicSurvey', 'translation', language, 'scale'], respondent) : pathOr([], ['publicSurvey', 'scale'], respondent);

        return questionScale.length ? questionScale : projectScale;
    }

    const question = getQuestion();

    if (!question) {
        return <Redirect to={`/survey_respondents/${props.id}`} />;
    }

    const answers = pathOr([], ['response', 'answers'], respondent);
    const translation = getTranslation();
    const buttonTranslation = pathOr('Ответить', ['translation', 'pageElements', 'submitButton'], respondent.publicSurvey);
    const chooseTextTranslation = pathOr(CHOOSE_TEXT, ['translation', 'pageElements', 'chooseText'], respondent.publicSurvey);
    const questionTitle = (language && translation.title ? translation.title : question.title) || (
        contains(question.type, TITLE_FROM_QUESTIONS) ? path(['questions', 0], language ? translation : question) :
            contains(question.type, TITLE_FROM_NAME) ? (language ? translation.name : question.name) :
                pathOr(chooseTextTranslation, ['publicSurvey', 'translation', language, 'chooseText'], respondent)) || chooseTextTranslation;

    return <div className={`question-${num}`}>
        <Title className='question-title' dangerouslySetInnerHTML={{ __html: language ? pathOr(respondent.publicSurvey.title, ['translation', language, 'title'], respondent.publicSurvey) : respondent.publicSurvey.title }} />
        <Container key={`question-${num}`}>
            <QuestionTitle dangerouslySetInnerHTML={{ __html: questionTitle }} id='question-title' />
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={{ submitting: true, invalid: true, submitFailed: true }}
                render={({ handleSubmit, invalid }) =>
                    <form className='answer' onSubmit={handleSubmit}>
                        <FieldContainer>
                            <Field
                                name='answers'
                                component={FIELDS[question.type]}
                                validate={VALIDATE(question)[question.type]}
                                respondent={respondent}
                                hiddenQuestionTemplate={getHiddenQuestionTemplate()}
                                question={question}
                                questionNumber={num}
                                translation={translation}
                                language={language}
                                scale={getScale()}
                                disabled={putSurveyRespondentQuestion.meta.pending}
                                options={question.options || {}}
                            />
                        </FieldContainer>
                        <Footer>
                            { QUESTIONS_HELP_TEXT[question.type] && QUESTIONS_HELP_TEXT[question.type][language] ? <Help>{QUESTIONS_HELP_TEXT[question.type][language]}</Help> : <Help /> }
                            <Button type='submit' disabled={invalid || putSurveyRespondentQuestion.meta.pending} id='submit'>
                                { language ? pathOr(buttonTranslation, ['publicSurvey', 'translation', language, 'submitButton'], respondent) : buttonTranslation}
                            </Button>
                        </Footer>
                    </form>
                } />
            <QuestionFooter>
                <QuestionsPagination>
                    { props.drivers.map((_, index) =>
                        <PaginationButton
                            key={`page-${index}`}
                            current={Number(num) === index}
                            answered={index < answers.filter(Boolean).length}
                            demo={isDemo(location)}
                            onClick={() => onChangePage(index)}
                        >
                            { index + 1 }
                        </PaginationButton>
                    )}
                </QuestionsPagination>
            </QuestionFooter>
        </Container>
    </div>;
}

export default withRouter(withAsyncActions(({ putSurveyRespondentQuestionAction }) => ({
    putSurveyRespondentQuestion: putSurveyRespondentQuestionAction
        .withSuccessHandler(({ getSurveyRespondent, history, match: { params: { num } }, id, drivers }) => {
            const next = Number(num) + 1;

            getSurveyRespondent.refresh();
            history.push(next >= drivers.length ? `/survey_respondents/${id}/thanks` : `/survey_respondents/${id}/driver/${next}`);
        })
        .withErrorHandler(() => {
            alert('Не удалось отправить ответ на вопрос');
        })
        .withOptions({ resetOnUnmount: true })
}))(Question));
