import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { Checkbox, Textarea } from '@planka/common';
import { path, join } from 'ramda';

import { INCORRECT_ANSWER_OPTIONS } from '../../constants/audit';
import withFieldWrapper from '../hocs/withFieldWrapper';
import RadioButtons from './formComponents/RadioButtons';
import Select from './formComponents/Select';
import ListenerField from './ListenerField';
import Complexity from '../Complexity';

const AuditTitle = styled.div`
    padding: 5px 10px;
    font-size: 1em;
    line-height: 1em;
    color: #fff;
    background-color: #2b3d4f;
    border-bottom: 1px solid #ccc;
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        border: 0;
        margin: 0 -10px;
        padding: 15px 20px;
    }
`;

const AuditFieldRow = styled.div`
    display: flex;
    @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 15px;
    }
`;

const AuditFieldCol = styled.div`
    border-bottom: 1px solid #ccc;

    @media (min-width: 767px) {
        width: 50%;
        padding: 10px 15px 15px;
        & + div {
            border-left: 1px solid #ccc;
        }
    }
    @media (max-width: 766px) {
        padding: 8px;
    }

    &.title {
        font-size: 1.3em;
        line-height: 1.5em;
        color: #fff;
        font-weight: 500;
        text-align: center;
        border: none;
    }
`;

const AuditComplexity = styled.span`
    display: inline-block;
    border: 1px solid #ccc;
    padding: 8px;
`;

const AuditSource = styled.div`
    border: 1px solid #ccc;
    padding: 8px;
    min-height: 38px;
`;

const AuditFieldDanger = styled.div`
    label {
        color: #2b3d4f;
    }
    & > div > div {
        background: rgb(43 61 79 / 20%);
    }
`;

const CheckboxWrap = styled.div`
    input:checked+label:before {
        top: 0;
        left: -35px;
        width: 21px;
        height: 21px;
        transform: none;
        border-width: 2px;
        background: #2B3D4F;
        border-color: #2B3D4F;
    }
    input:checked+label:after {
        top: 8px;
        left: -25px;
        width: 4px;
        height: 10px;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
    }
`;

const complexityLevel = {
    1: 'Easy',
    2: 'Middle',
    3: 'Hard',
}

const FormInfo = withFieldWrapper(({ content }) => content || '');

const AuditCheckboxes = withFieldWrapper(
    ({ options }) => options.map((option) => (
        <CheckboxWrap key={option.id}>
            <Field
                name={option.id}
                component={Checkbox}
                text={option.name}
                defaultValue={false}
                noWrap
            />
        </CheckboxWrap>
    ))
);

export default class AuditFormFields extends Component {
    render() {
        const { t, data } = this.props;

        return <Fragment>
            <AuditTitle>
                <AuditFieldRow>
                    <AuditFieldCol className='title'>
                        {t('audit.questionInfoTitle')}:
                    </AuditFieldCol>
                    <AuditFieldCol className='title'>
                        {t('audit.questionAuditTitle')}:
                    </AuditFieldCol>
                </AuditFieldRow>
            </AuditTitle>
            <AuditFieldRow>
                <AuditFieldCol>
                    <FormInfo
                        label={t('audit.complexity')}
                        content={<AuditComplexity><Complexity level={data.complexity} /> {complexityLevel[data.complexity] ? t(`audit.complexity${complexityLevel[data.complexity]}`) : '-'}</AuditComplexity>}
                    />
                    <FormInfo
                        label={t('audit.informationSource')}
                        content={<AuditSource>{data.informationSource || ''}</AuditSource>}
                    />
                    <FormInfo
                        label={t('audit.category')}
                        content={<div>{join('/', [path(['category', 'title'], data), path(['competence', 'title'], data), path(['indicator', 'title'], data)].filter(Boolean))}</div>}
                    />
                    { data.note &&
                            <AuditFieldDanger>
                                <FormInfo
                                    label={t('audit.note')}
                                    content={<AuditSource>{ data.note }</AuditSource>} />
                            </AuditFieldDanger>
                    }
                </AuditFieldCol>
                <AuditFieldCol>
                    <Field
                        name='incorrectQuestion'
                        component={RadioButtons}
                        label={t('audit.incorrectQuestion')}
                        required
                        options={[
                            { id: false, name: t('audit.correct') },
                            { id: true, name: t('audit.incorrect') },
                        ]}
                    />
                    <ListenerField listenFieldName='incorrectQuestion'>
                        {({ value }) => (value === true || value === false) && (
                            <Fragment>
                                { !!value &&
                                    <AuditCheckboxes
                                        label=''
                                        options={INCORRECT_ANSWER_OPTIONS.map(({ id }) => ({
                                            id,
                                            name: t(`audit.incorrectQuestions.${id}`),
                                        }))}
                                    />
                                }
                                <Field
                                    name='message'
                                    component={Textarea}
                                    label={t('audit.comment')}
                                />
                                <Field
                                    name='complexity'
                                    component={Select}
                                    allowClear={false}
                                    isSearchable={false}
                                    label={t('audit.complexity')}
                                    required
                                    options={[
                                        { id: 1, name: <span><Complexity level={1} /> {t('audit.complexityEasy')}</span> },
                                        { id: 2, name: <span><Complexity level={2} /> {t('audit.complexityMiddle')}</span> },
                                        { id: 3, name: <span><Complexity level={3} /> {t('audit.complexityHard')}</span> },
                                    ]}
                                />
                                <Field
                                    name='informationSource'
                                    component={Textarea}
                                    label={t('audit.informationSource')}
                                />
                            </Fragment>
                        )}
                    </ListenerField>
                </AuditFieldCol>
            </AuditFieldRow>
        </Fragment>;
    }
}
