import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { withTranslation } from 'react-i18next';

import Input from './formComponents/Input';
import Button from '../Button';
import withFormWrapper from '../hocs/withFormWrapper';

class CompanyPageForm extends Component {
    render() {
        const { t, invalid, colors } = this.props;

        return <Fragment>
            <Field
                name='code'
                component={Input}
                label={t('company.codeLabel')}
                colors={colors} />
            <Button type='submit' disabled={invalid} bg={colors.input}>
                {t('company.toTests')}
            </Button>
        </Fragment>
    }
}

const validationSchema = yup.object().shape({
    code: yup.string().required()
});

export default withFormWrapper(withTranslation()(CompanyPageForm), {
    validationSchema
});
