import ruTranslations from './ru';
import enTranslations from './en';

export default {
    ru: {
        translation: ruTranslations
    },
    en: {
        translation: enTranslations
    },
};
