import React from 'react';
import { without, append, includes } from 'ramda';

import withQuestionFieldWrapper from '../withQuestionFieldWrapper';

const Individual = props => {
    const { value, scale, disabled } = props;
    const onChange = e => {
        const value = e.target.value;
        const current = props.value || [];
        const values = includes(value, current) ? without([value], current) : append(value, current);

        props.onChange(values.length ? values : null);
    }

    return scale.map((item, index) =>
        <div className='my-2' key={`variant-${index}`}>
            <label className='checkbox'>
                <input
                    type='checkbox'
                    value={item}
                    disabled={disabled}
                    checked={value ? includes(item, value || []) : false}
                    onChange={onChange} /><span className="checked-box" /> <span dangerouslySetInnerHTML={{ __html: item.name || item }} />
            </label>
        </div>
    );
}

export default withQuestionFieldWrapper(Individual);
