import React from 'react';
import styled from 'styled-components';
import { is } from 'ramda';

import withFieldWrapper from '../withFieldWrapper';

const Label = styled.label`
    position: relative;
    display: block;
    clear: both;
    input {
        position: absolute;
        margin-left: -15px;
        opacity: 0;
        &:checked + .checked-box:before {
            display: block;
            width: 5px;
            height: 10px;
            margin: 4px auto auto;
            background: transparent;
            border-color: transparent #2b3d4f #2b3d4f transparent;
            border-style: solid;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            opacity: 1;
        }
    }
    .checked-box {
        cursor: pointer;
        display: block;
        margin-right: 10px;
        float: left;
        min-width: 24px;
        width: 24px;
        height: 24px;
        min-width: 24px;
        background: #ededed;
        border: 0;
        &:before {
            content: "";
            opacity: 0;
            transition: opacity .4s;
        }
    }
`;

const Checkbox = props => {
    const { input: { value }, label, text } = props;
    const onChange = e => props.onChange(e.target.checked);

    return <Label className='checkbox checkbox-field'>
        <input
            type='checkbox'
            checked={!!value}
            onChange={onChange} />
        <span className='checked-box' /> { label ? <div dangerouslySetInnerHTML={{ __html: label }} /> : (
            is(Function, text) ? text(props) : text || null
        ) }
    </Label>;
}

export default withFieldWrapper(Checkbox);
