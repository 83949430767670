import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { pathOr, path, fromPairs } from 'ramda';
import styled from 'styled-components';
import Twig from 'twig';

import Number from './formComponents/Number';
import Select from './formComponents/Select';
import Radio from './formComponents/Radio';
import Input from './formComponents/Input';
import Checkbox from './formComponents/Checkbox';
import Button from '../../Button';
import { ButtonLoader } from '../../Loader';
import { isDemo } from '../../../utils/demo';

const FormComponent = styled.form`
    padding: 0 0 0 40px;
    @media (max-width: 812px) {
        padding: 0;
    }
`;

const FieldContainer = styled.div`
    padding: 10px 0 15px;
    @media (max-width: 767px) {
        padding: 10px 0 15px;
        border: 0;
    }
    label{
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 8px;
        display: inline-flex;
        align-items: center;
        &.checkbox,&.radio {
            font-weight: normal;
        }
        @media (max-width: 767px) {
            font-size 14px;
        }
    }
    .form-label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        @media (max-width: 767px) {
            font-size 14px;
        }
    }
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        padding: 10px 0;
    }
`;

const FIELDS = {
    input: Input,
    number: Number,
    select: Select,
    radio: Radio,
    checkbox: Checkbox
};

class FormField extends Component {
    getHiddenQuestionTemplate = () => {
        const hideCondition = path(['hideCondition'], this.props.field);

        if (!hideCondition) {
            return null;
        }

        if (!this.hiddenQuestionTemplate) {
            this.hiddenQuestionTemplate = Twig.twig({
                data: hideCondition
            });
        }

        return this.hiddenQuestionTemplate;
    }

    isHiddenQuestion = () => {
        const hiddenQuestionTemplate = this.getHiddenQuestionTemplate();

        return hiddenQuestionTemplate ?
            hiddenQuestionTemplate.render({
                value: this.props.formValues[this.props.field.key],
                ...fromPairs(this.props.fields.map(({ key }) => ([ key, this.props.formValues[key] ]))),
                form: fromPairs(this.props.fields.map(({ key, title }) => ([ title, this.props.formValues[key] ])))
            }) === 'true' : false;
    }

    getFieldProps = () => {
        const { language, field, index, translation } = this.props;
        const { options, title } = field;

        return {
            options: (options || []).map((name, i) => language ? pathOr(name, ['options', i], translation[index]) : name),
            label: language ? pathOr(title, [index, 'title'], translation) : title
        };
    }

    render() {
        const { field } = this.props;

        return this.isHiddenQuestion() ? null :
            <Field
                name={field.key}
                component={FIELDS[field.type]}
                fieldType={field.type}
                validate={value => !value && field.required ? 'required' : undefined}
                clearOnUnmount
                {...this.getFieldProps()} />;
    }
}

export default class SurveyRespondentForm extends Component {
    onSubmit = sections => {
        if (isDemo(this.props.location)) {
            this.props.history.push(`/survey_respondents/${this.props.id}/driver/0${this.props.location.search}`);
        } else {
            this.props.postSurveyRespondentStart.dispatch({
                id: this.props.id,
                data: {
                    sections
                }
            });
        }
    }

    render() {
        const { fields, language, respondent, translation, postSurveyRespondentFinish } = this.props;
        const buttonTranslation = pathOr('Начать опрос', ['translation', 'pageElements', 'startButton'], respondent.publicSurvey);

        return <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit, invalid, values }) =>
                <FormComponent onSubmit={handleSubmit}>
                    { fields.map((field, index) =>
                        <FieldContainer key={field.key}>
                            <FormField
                                field={field}
                                index={index}
                                language={language}
                                formValues={values}
                                fields={fields}
                                translation={translation} />
                        </FieldContainer>
                    )}
                    <Footer>
                        <Button type='submit' disabled={invalid || postSurveyRespondentFinish.meta.pending} id='start'>
                            <ButtonLoader loading={postSurveyRespondentFinish.meta.pending}>
                                { language ? pathOr(buttonTranslation, ['publicSurvey', 'translation', language, 'startButton'], respondent) : buttonTranslation}
                            </ButtonLoader>
                        </Button>
                    </Footer>
                </FormComponent>
            } />;
    }
}
