import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import { pushRollbarError } from '../utils/rollbar';

const clearState = {
    error: false,
    path: '',
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 100vh;
`;

export default class RouteComponent extends Component {
    state = clearState;

    componentDidCatch(error) {
        this.setState({
            error: true,
            path: this.props.path,
        });

        pushRollbarError(error);
    }

    componentDidUpdate() {
        if (this.state.error && this.props.path !== this.state.path) {
            this.setState(clearState);
        }
    }

    resetError = () => {
        window.history.back();
    }

    render() {
        return this.state.error ?
            <Container>
                <p>Не удалось отобразить данные</p>
            </Container>
        : <Route {...this.props} />;
    }
}
