import React, { Component } from 'react';
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import DocumentHeader from './test/DocumentHeader';

const Container = styled.div`
    background: #fff;
    overflow: hidden;
    @media (max-width: 767px) {
        margin-top: 0;
        padding: 15px 0;
    }
`;

const Header = styled.h2`
    padding: 15px;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1.5em;
    font-weight: normal;
    color: #fff;
    background-color: #2b3d4f;
    @media (max-width: 767px) {
        margin: 0 -10px;
        padding: 15px 20px;
    }
`;

const Text = styled.h4`
    font-size: 24px;
    padding: 25px;
    @media (max-width: 767px) {
            padding: 5px;
    }
`;

const Content = styled.div`
    padding: 50px;
    text-align: center;
    font-size: 1.2em;
    border: 3px solid #2c3d4e;
    svg {
        margin: 15px 0;
    }
    @media (max-width: 767px) {
        border: 0;
    }
`;

export default withTranslation()(class AuditSuccess extends Component {
    render() {
        const { t } = this.props;

        return <Container>
            <DocumentHeader title={t('test.thanks')} />
            <Header>{t('test.end')}</Header>
            <Content>
                <Text>{t('test.endedAudit')}</Text>
                <CheckCircle size={100} color='#75c464' />
            </Content>
        </Container>;
    }
});
