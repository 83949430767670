import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';
import { path, pathOr } from 'ramda';
import { withTranslation } from 'react-i18next';

import { getCompany } from '../actions/asyncActions';
import Loader from './Loader';
import Wrapper from './Wrapper';
import CompanyPageForm from './forms/CompanyPageForm';

const LoaderContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WelcomeText = styled.div`
    padding: 15px 0 30px 0;
    font-size: 16px;
    line-height: 1.7em;
    p {
        margin: 1em 0;
    }
    b,strong {
        font-weight: 400;
    }
    ol, ul {
        margin-bottom: 10px;
        padding-left: 40px;
    }
    h1{
        font-weight: 300;
        margin-bottom: 15px;
    }
`;

const ErrorTitle = styled.h1`
    font-weight: 300;
    text-shadow: 1px 6px 6px #868686;
    font-size: 19.4em;
    line-height: 0.8em;
    margin-bottom: 75px;
`;

const ErrorDescription = styled.h2`
    font-weight: 300;
    font-size: 2.4em;
`;

const VerticalCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 130px);
`;

class CompanyPage extends Component {
    onSubmit = ({ code }) => {
        const { history, match } = this.props;

        history.push(`/tests/${match.params.company}/${code}`);
    }

    render() {
        const { t, getCompany: { data, meta } } = this.props;

        return meta.pending ?
            <LoaderContainer>
                <Loader />
            </LoaderContainer> : meta.error ?
            <Wrapper>
                <VerticalCenter>
                    <ErrorTitle>{path(['status'], meta.error)}</ErrorTitle>
                    <ErrorDescription>{t('test.notFound')}</ErrorDescription>
                </VerticalCenter>
            </Wrapper> :
            <Wrapper designScheme={data.designScheme}>
                { data.welcomeText && <WelcomeText dangerouslySetInnerHTML={{ __html: data.welcomeText }} /> }
                <CompanyPageForm
                    formAction={this.onSubmit}
                    colors={pathOr({}, ['designScheme', 'colors'], data)} />
            </Wrapper>;
    }
}

export default withAsyncActions({
    getCompany: getCompany
        .withPayload(({ match }) => match.params.company)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(withTranslation()(CompanyPage));
