import React, { Component } from 'react';

import { getAudit, postAuditStart, postAuditFinish, getAuditQuestion, postAuditAnswer, postAuditStartFinish } from '../actions/asyncActions';

import Response from './test/Response';

export default class Audit extends Component {
    render() {
        return (
            <Response
                {...this.props}
                responseType='audits'
                getResponseAction={getAudit}
                postResponseFinishAction={postAuditFinish}
                postResponseStartAction={postAuditStart}
                getResponseQuestionAction={getAuditQuestion}
                postResponseAnswerAction={postAuditAnswer}
                postResponseStartFinishAction={postAuditStartFinish}
                isAudit
            />
        );
    }
}
