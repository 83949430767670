import React from 'react';

import withFieldWrapper from '../withFieldWrapper';
import { StyledInput } from './Input';

const Number = props => {
    const { input: { value }} = props;
    const onChange = e => props.onChange(e.target.value);

    return <StyledInput
        type='number'
        value={value}
        onChange={onChange} />;
}

export default withFieldWrapper(Number);
