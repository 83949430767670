import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

export default class ListenerFieldComponent extends Component {
    static propTypes = {
        listenFieldName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
        ]).isRequired,
        children: PropTypes.func,
    }

    render() {
        const { listenFieldName } = this.props;

        return (
            <Field name={listenFieldName}>
                { ({ input }) => this.props.children(input) }
            </Field>
        );
    }
}

