import React, { Component, Fragment } from 'react';
import { SagaProvider } from 'react-async-client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

import Routes from './Routes';
import { sagaMiddleware, history } from '../store/middlewares';
import { pushRollbarError } from '../utils/rollbar';
import ScrollToTop from './ScrollToTop';

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Geometria',sans-serif;
    }

    html, body, #root {
        height: 100%;
    }

    body {
        font-family: 'Geometria', sans-serif;
        background: #fff;
        font-size: 14px;
        font-weight: 300;
        color: #2b3d4f;
        @media (max-width: 767px) {
            background: #fff;
        }
    }

    a {
        color: #1890ff;
        text-decoration: none;
        padding: 0;
        &:hover {
            color: #23527c;
        }
    }
    .react-confirm-alert-body {
        font-family: inherit;
        border-radius: 0;
        text-align: center;
        .react-confirm-alert-button-group {
            justify-content: space-evenly;
            margin-top: 30px;
            button:first-child {
                background-color: #f54d2e;
                color: #fff;
                padding: 10px 16px;
                font-size: 14px;
                line-height: 1.33;
                border-radius: 3px;
                display: inline-block;
                margin-bottom: 0;
                font-weight: 300;
                text-align: center;
                touch-action: manipulation;
                cursor: pointer;
                border: 1px solid transparent;
                white-space: nowrap;
                user-select: none;
                &:hover {
                    opacity: 0.9;
                }
                &:focus {
                    outline: none;
                }
            }
            button:last-child {
                color: #000;
                background-color: transparent;
                font-weight: 300;
                text-align: center;
                touch-action: manipulation;
                cursor: pointer;
                border: 1px solid transparent;
                white-space: nowrap;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                border-radius: 3px;
                margin-left: 5px;
                &:hover {
                    background-color: #E5E5E5;
                }
            }
        }
    }
    #question-title {
        ul,ol {
            padding: 0 0 0 20px;
        }
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 100vh;
`;

export default class App extends Component {
    state = {
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    render() {
        const { store } = this.props;

        return this.state.error ?
            <Container>Не удалось запустить приложение</Container> :
            <Fragment>
                <GlobalStyles />
                <SagaProvider sagaMiddleware={sagaMiddleware}>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <ScrollToTop>
                                <Routes />
                            </ScrollToTop>
                        </ConnectedRouter>
                    </Provider>
                </SagaProvider>
            </Fragment>;
    }
}
