import React from 'react';
import { is } from 'ramda';
import styled from 'styled-components';

import withFieldWrapper from '../withFieldWrapper';

const StyledSelect = styled.select`
    outline: 0;
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    font-size: 16px;
    background-color: #ededed;
    border: 1px solid transparent;
    font-weight: bold;
    color: #2b3d4f;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L9 9L15 3' stroke='%232B3D4F' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 98% center;
    -webkit-appearance: none;
    -moz-appearance: none;
`;

const SelectNative = props => {
    const { input: { value, name }, options } = props;
    const items = is(Array, options) ? options : [];
    const onChange = e => props.onChange(e.target.value);

    return <StyledSelect value={value} onChange={onChange}>
        <option></option>
        { items.map((opt, index) =>
            <option key={`${name}-${index}`} value={opt}>
                { opt }
            </option>
        )}
    </StyledSelect>;
}

export default withFieldWrapper((props) => {
    return <SelectNative {...props} />;
});
