import React from 'react';
import styled from 'styled-components';

import withFieldWrapper from '../withFieldWrapper';

export const StyledInput = styled.input`
    outline: 0;
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    font-size: 16px;
    background-color: #ededed;
    border: 1px solid transparent;
    font-weight: bold;
    color: #2b3d4f;
    background-image: none;
    appearance: none;
`;

const Input = props => {
    const { input: { value }} = props;
    const onChange = e => props.onChange(e.target.value);

    return <StyledInput
        type='text'
        value={value}
        onChange={onChange} />;
}

export default withFieldWrapper(Input);
