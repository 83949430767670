import React, { Component } from 'react';
import { assoc, isEmpty, path, uniq, propEq, filter, findIndex, equals, includes, append, without, dissoc } from 'ramda';
import styled from 'styled-components';

import withFieldWrapper from '../withFieldWrapper';

const ValueBox = styled.div`
    padding: 0px 4px 0px 8px;
    margin: 10px 3px 3px 0;
    background-color: #ff4136;
    display: inline-block;
    color: #fff;
    font-size: 12px;
`;

const ValueBoxRemove = styled.div`
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    padding: 5px;
`;

class OtherField extends Component {
    componentWillUnmount() {
        this.props.onChange(null);
    }

    onChange = e => {
        this.props.onChange(e.target.value);
    }

    render() {
        const { value } = this.props;

        return <input
            value={value || ''}
            onChange={this.onChange}
            placeholder='Введите другой вариант ответа'
            style={{ marginBottom: 10 }} />;
    }
}

class Select extends Component {
    onChange = (value, index) => {
        const current = this.props.input.value || {};
        const values = assoc(index, append(value, current[index] || []), current);

        this.props.onChange(isEmpty(values) ? null : values);
    }

    removeValue = (field, v) => {
        const current = this.props.input.value || {};
        const value = without([v], current[field] || []);
        const values = isEmpty(value) ? dissoc(field, current) : assoc(field, value, current);

        this.props.onChange(isEmpty(values) ? null : values);
    }

    onChangeOther = (value, index) => {
        const current = this.props.input.value || {};
        const values = !value ? dissoc(index, current) : assoc(index, value, current);

        this.props.onChange(isEmpty(values) ? null : values);
    }

    getData = () => {
        const { questions, language, translatedQuestions } = this.props;
        let items = questions.map((question, index) => language && translatedQuestions[index] ? translatedQuestions[index] : question);
        items = items.map((question, index) => {
            const paths = question.split('|');
            return {
                group: path([0], paths),
                title: path([1], paths),
                other: path([2], paths),
                path: question,
                index,
                parentIndex: findIndex(equals(`${path([0], paths)}|${path([1], paths)}`), items)
            };
        });
        const data = uniq(items.map(({ group }) => group)).map(group => ({
            group,
            fields: filter(propEq('group', group), items)
        }));

        return data;
    }

    render() {
        const { input: { value = {} }, disabled } = this.props;
        const scale = this.props.scale.map((label, index) => ({ label, index }));

        return this.getData().map((item, index) =>
            <div key={`group-${index}`}>
                <div className='header-group'>{ item.group }</div>
                { filter(({ hide }) => !hide, item.fields).map((field, i) => (!field.other || (!!field.other && includes(findIndex(propEq('label', field.other), scale), value[field.parentIndex] || []))) &&
                    <div key={`field-${index}-${i}`} style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '50%' }}>{ !!field.other ? null : field.title }</div>
                        <div style={{ width: '50%' }}>
                            { !!field.other ?
                                <OtherField
                                    value={value[field.index] || ''}
                                    onChange={value => this.onChangeOther(value, field.index)} /> :
                                <div>
                                    <div>
                                        { (value[field.index] || []).map(v =>
                                            <ValueBox key={`variant-value-${index}-${i}-${v}`}>
                                                { this.props.scale[v] }
                                                <ValueBoxRemove onClick={() => this.removeValue(field.index, v)}>x</ValueBoxRemove>
                                            </ValueBox>
                                        )}
                                    </div>
                                    <select
                                        className='mt-2'
                                        disabled={disabled}
                                        style={{ marginBottom: 10 }}
                                        value=''
                                        onChange={e => this.onChange(Number(e.target.value), field.index)}>
                                        <option></option>
                                        { filter(scaleValue => !includes(scaleValue.index, value[field.index] || []), scale).map(variant =>
                                            <option key={`variant-${index}-${i}-${variant.index}`} value={variant.index}>
                                                { variant.label }
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withFieldWrapper(Select);
