import React, { Component } from 'react';

import { getSurveyRespondent, postSurveyRespondentPda, postSurveyRespondentStart, postSurveyRespondentFinish, putSurveyRespondentQuestion, getSurveyRespondentQuestion, postSurveyRespondentStartFinish } from '../actions/asyncActions';

import Response from './survey/Response';

export default class SurveyRespondent extends Component {
    render() {
        return (
            <Response
                {...this.props}
                getSurveyResponseAction={getSurveyRespondent}
                postSurveyResponseFinishAction={postSurveyRespondentFinish}
                postSurveyResponsePdaAction={postSurveyRespondentPda}
                postSurveyResponseStartAction={postSurveyRespondentStart}
                getSurveyRespondentQuestionAction={getSurveyRespondentQuestion}
                putSurveyRespondentQuestionAction={putSurveyRespondentQuestion}
                postResponseStartFinishAction={postSurveyRespondentStartFinish}
            />
        );
    }
}
