import React, { Component } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { withTranslation } from 'react-i18next';

import Loader from './Loader';
import LogoImg from '../assets/logo.svg';
import LogoImgEn from '../assets/logo_en.svg';
import { changeLanguage } from '../utils/i18n';

const Container = styled.div`
    min-height: 100%;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    position: relative;
    height: 80px;
    margin-bottom: 30px;
    background-color: ${({ bg }) => bg || '#fff'};
    color: ${({ color }) => color || '#000'};
    &:before{
        content: '';
        display: table;
        clear: both;
    }
    &:after {
        position: absolute;
        top: 100%;
        z-index: 10;
        width: 100%;
        height: 16px;
        pointer-events: none;
        content: "";
        background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.05)),to(transparent));
        background: -o-linear-gradient(top,rgba(0,0,0,.05),transparent);
        background: linear-gradient(180deg,rgba(0,0,0,.05),transparent);
    }
`;

const HeaderContent = styled.div`
    padding: 0 15px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    @media (min-width: 992px) {
        width: 100%;
        max-width: 1300px;
        padding: 0 15px;
    }
`;

const Logo = styled.div`
    background-image: url(${({ url, isRu }) => url ? url : (isRu ? LogoImg : LogoImgEn)});
    height: 55px;
    width: 220px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    @media (max-width: 767px) {
        height: 40px;
    }
`;

const HeaderName = styled.div`
    font-size: 1.1em;
`;

const Content = styled.div`
    padding: 0 15px;
    margin: 20px auto;
    flex: 1 1 auto;
    width: 100%;
    color: ${({ color }) => color};
    @media (min-width: 992px) {
        width: 100%;
        max-width: 1300px;
        padding: 0 15px;
    }
    @media (max-width: 769px) {
        padding: 0 10px;
    }
    .answer {
        border: 3px solid #2c3d4e;
        @media (max-width: 767px) {
            border: 0;
        }
    }
`;

const Footer = styled.div`
    min-height: 50px;
    border-top: 1px solid #ccc;
    background-color: ${({ bg }) => bg || '#fff'};
    color: ${({ color }) => color || '#000'};
    padding: 15px;
`;

const LoaderContainer = styled.div`
    height: calc(100vh - 110px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LanguageChange = styled.a`
    cursor: pointer;
`;

const GlobalStyles = createGlobalStyle`
    html body {
        background: ${({ bg }) => bg};
        font-family: ${({ fontFamily }) => fontFamily};
    }
`;

export default withTranslation()(class Wrapper extends Component {
    render() {
        const { children, headerName, pending, designScheme, i18n, t } = this.props;
        const { colors = {}, fontFamily, logo, footerText } = designScheme || {};
        const isRu = i18n.language === 'ru';
        const nextLang = isRu ? 'EN' : 'RU';

        return <Container>
            <GlobalStyles bg={colors.background} fontFamily={fontFamily} />
            <Header bg={colors.header} color={colors.headerText}>
                <HeaderContent>
                    <Logo isRu={isRu} url={logo ? `/api/images/${logo}` : null} />
                    <HeaderName>{ headerName }</HeaderName>
                    <LanguageChange onClick={() => changeLanguage(nextLang.toLocaleLowerCase(), t)}>{nextLang}</LanguageChange>
                </HeaderContent>
            </Header>
            { pending ?
                <LoaderContainer>
                    <Loader />
                </LoaderContainer> :
                <Content color={colors.text}>
                    { children }
                </Content>
            }
            { footerText &&
                <Footer bg={colors.footer} color={colors.footerText}>
                    <div dangerouslySetInnerHTML={{ __html: footerText }} />
                </Footer>
            }
        </Container>;
    }
});
