import React, { Component } from 'react';
import styled from 'styled-components';
import { PauseCircleFilled } from '@styled-icons/material/PauseCircleFilled';
import { pluralize } from 'numeralize-ru';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import DocumentHeader from './DocumentHeader';
import Button from '../Button';

const Container = styled.div`
    background: #fff;
    @media (max-width: 767px) {
        box-shadow: none;
    }
`;

const Title = styled.div`
    padding: 15px;
    font-size: 1.3em;
    line-height: 1.5em;
    font-weight: normal;
    color: #fff;
    background-color: #2b3d4f;
    @media (max-width: 767px) {
        margin: 0 -10px;
        padding: 15px 20px;
    }
`;

const Content = styled.div`
    padding: 40px 130px;
    text-align: center;
    border: 3px solid #2c3d4e;
    @media (max-width: 767px) {
        border: 0;
    }
`;

const Text = styled.div`
    font-size: 17px;
`;

const IconWrapper = styled.div`
    margin: 20px 0;
`;

class TestPaused extends Component {
    render() {
        const { t, title, respondent, history, match: { params: { id } } } = this.props;
        const completed = respondent.currentQuestion + 1;

        return <Container>
            <DocumentHeader title={`${t('test.pause')} | ${title}`} />
            <Title>{t('test.onPause')}</Title>
            <Content>
                <Text>{t('test.pauseSet')}</Text>
                <Text>{t('test.complete')} {completed} {pluralize(completed, t('test.completeQuestion'), t('test.completeQuestionOf'), t('test.completeQuestions'))} {t('test.completeFrom')} {respondent.questionsCount}.</Text>
                <IconWrapper><PauseCircleFilled size={100} color='#E4E4E4' /></IconWrapper>
                <Button onClick={() => history.push(`/respondents/${id}/question/${respondent.currentQuestion + 1}`)}>{t('test.next')}</Button>
            </Content>
        </Container>;
    }
}

export default withRouter(withTranslation()(TestPaused));
