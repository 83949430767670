import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
    margin-bottom: 15px;
    &:before,
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
    input {
        border-color: ${({ colors }) => colors.elementStatistic};
    }
    label,
    input + label {
        color: ${({ colors }) => colors.textInput};
    }
`;

const Label = styled.label`
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    max-width: 100%;
    position: relative;
    @media (max-width: 767px) {
        margin-bottom: 15px;
    }
    ${({ required }) => required && css`
        &:before {
            content: '*';
            color: #E71D33;
            position: absolute;
            left: -8px;
        }
    `}
`;

export default WrappedComponent =>
    class FieldWrapper extends Component {
        static defaultProps = {
            clearOnUnmount: true,
            noWrap: false,
            colors: {}
        };

        componentWillUnmount() {
            this.props.clearOnUnmount && this.onChange(null)
        }

        onChange = value => {
            const { input, onChange } = this.props;

            input && input.onChange(value);
            onChange && onChange(value);
        }

        render() {
            const { label, noWrap, colors, required } = this.props;
            const field = <WrappedComponent {...this.props} onChange={this.onChange} />;

            return noWrap ? field : <Container colors={colors}>
                <Label required={required}>{ label }</Label>
                {field}
            </Container>
        }
    }
