import React, { Component } from 'react';
import RSelect from 'react-select';
import { find, prop, propEq, path } from 'ramda';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const Container = styled.div`
    .select__control {
        background: none;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-shadow: none;
        &--menu-is-open,
        &:hover {
            border: 1px solid #1f1f1f;
        }
    }
    .select__value-container {
        padding: 2px 12px;
    }
    .select__single-value {
        margin: 0;
    }
    .select__option{
        &.select__option--is-focused{
            background: #ffe4e7;
        }
    }
    .select__option{
        &:active{
            background: #ffb6be;
        }
        &.select__option--is-selected{
            background: #f54d2e;
        }
    }
`;

class Select extends Component {
    static defaultProps = {
        valuePath: 'id',
        namePath: 'name',
        options: []
    };

    getOptions = () => {
        const { options, valuePath, namePath } = this.props;

        return options.map(item => ({
            value: prop(valuePath, item),
            label: prop(namePath, item),
            option: item,
        }));
    }

    onChange = value => {
        const { onChange } = this.props;

        onChange(path(['value'], value));
    }

    render() {
        const { t, input, pending, placeholder, allowClear, isSearchable } = this.props;
        const options = this.getOptions();

        return <Container>
            <RSelect
                classNamePrefix='select'
                className='select'
                value={find(propEq('value', input.value), options)}
                options={options}
                onChange={this.onChange}
                isLoading={pending}
                placeholder={placeholder || null}
                isClearable={allowClear}
                isSearchable={isSearchable}
                components={{
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: () => <div>{t('form.select.empty')}</div>
                }} />
        </Container>;
    }
}

export default withFieldWrapper(withTranslation()(Select));
