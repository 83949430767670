import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import { Switch } from 'react-router-dom';
import { addIndex, findIndex, filter, path, pathOr, isEmpty, isNil, endsWith } from 'ramda';
import styled from 'styled-components';
import Twig from 'twig';

import NoMatch from '../NoMatch';
import SurveyRespondentForm from '../forms/survey/SurveyRespondentForm';
import SurveyPdaForm from '../forms/survey/SurveyPdaForm';
import Question from './Question';
import Thanks from './Thanks';
import Wrapper from '../Wrapper';
import Button from '../Button';
import { ButtonLoader } from '../Loader';
import Route from '../Route';
import { isDemo, getToken } from '../../utils/demo';
import { setTheme } from '../../utils/cssVariables';
import { pushRollbarError } from '../../utils/rollbar';

const Container = styled.div`
    display: flex;
    & > div, & > form {
        width: 50%;
    }
    @media (max-width: 812px) {
        flex-direction: column;
        & > div, & > form {
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        border: none;
    }
`;

const Description = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: #2b3d4f;
    p {
        margin: 1em 0;
        background-color: transparent!important;
    }
    b {
        font-weight: 400;
    }
    ol, ul {
        margin-bottom: 10px;
        padding-left: 40px;
    }
`;

const Title = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 32px;
    position: relative;
    @media (max-width: 767px) {
        font-size: 28px;
    }
`;

const Footer = styled.div`
    padding: 10px 0 10px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    @media (max-width: 767px) {
        padding: 10px 0;
    }
`;

const ErrorWrapper = styled.div`
    text-align: center;
    margin-top: 15px;
    button {
        margin-top: 15px;
    }
`;

const ErrorTitle = styled.h1`
    font-weight: 300;
    text-shadow: 1px 6px 6px #868686;
    font-size: 19.4em;
    line-height: 0.8em;
    margin-bottom: 75px;
`;

const ErrorDescription = styled.h2`
    font-weight: 300;
    font-size: 2.4em;
`;

const VerticalCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 130px);
`;

let templates = {};
const getDrivers = data => {
    const drivers = pathOr([], ['publicSurvey', 'drivers'], data);
    const answers = pathOr([], ['response', 'answers'], data);

    return addIndex(filter)((driver, index) => {
        const hideDriver = path(['options', 'hideDriver'], driver);

        if (!hideDriver) {
            return true;
        }

        if (!templates[index]) {
            templates[index] = Twig.twig({
                data: hideDriver
            });
        }

        return templates[index].render({
            answers
        }) === 'true';
    }, drivers.map((driver, index) => ({
        ...driver,
        translations: pathOr({}, ['publicSurvey', 'translation', 'drivers', index], data)
    })));
}

class RespondentMain extends Component {
    renderInfoSection = publicSurvey => {
        const { language } = this.props;
        const title = language ? pathOr(publicSurvey.title, ['translation', this.props.language, 'title'], publicSurvey) : publicSurvey.title;
        const welcomeText = language ? pathOr(publicSurvey.welcomeText, ['translation', this.props.language, 'welcomeText'], publicSurvey) : publicSurvey.welcomeText;

        return (title || welcomeText) ?
            <div>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
                <Description dangerouslySetInnerHTML={{ __html: welcomeText }} />
            </div> : null;
    }

    start = () => {
        if (isDemo(this.props.location)) {
            this.props.history.push(`/survey_respondents/${this.props.id}/driver/0${this.props.location.search}`);
        } else {
            this.props.postSurveyRespondentStart.dispatch({
                id: this.props.id,
                data: {},
            });
        }
    }

    renderForm = sections => {
        const buttonTranslation = pathOr('Начать опрос', ['translation', 'pageElements', 'startButton'], this.props.respondent.publicSurvey);

        return sections.length ?
            <SurveyRespondentForm
                location={this.props.location}
                id={this.props.id}
                respondent={this.props.respondent}
                history={this.props.history}
                postSurveyRespondentFinish={this.props.postSurveyRespondentFinish}
                postSurveyRespondentStart={this.props.postSurveyRespondentStart}
                fields={sections} /> :
            <Footer>
                <Button type='button' onClick={this.start} id='start' disabled={this.props.postSurveyRespondentStart.meta.pending}>
                    <ButtonLoader loading={this.props.postSurveyRespondentStart.meta.pending}>
                        { this.props.language ? pathOr(buttonTranslation, ['publicSurvey', 'translation', this.props.language, 'startButton'], this.props.respondent) : buttonTranslation}
                    </ButtonLoader>
                </Button>
            </Footer>;
    }

    renderPdaForm = () => {
        return (
            <SurveyPdaForm
                location={this.props.location}
                id={this.props.id}
                respondent={this.props.respondent}
                postSurveyRespondentPda={this.props.postSurveyRespondentPda}
            />
        );
    }

    goToQuestion = () => {
        const { id, respondent, history } = this.props;
        const index = findIndex(isNil, pathOr([], ['response', 'answers'], respondent));

        history.push(`/survey_respondents/${id}/driver/${index < 0 ? 0 : index}`);
    }

    renderData = () => {
        const { respondent } = this.props;
        const publicSurvey = pathOr({}, ['publicSurvey'], respondent);
        const buttonTranslation = pathOr('Продолжить', ['translation', 'pageElements', 'continueButton'], publicSurvey);

        switch (respondent.status) {
            case 'sent':
                return this.renderForm(publicSurvey.sections || []);
            case 'started':
                return <Footer>
                    <Button type='button' onClick={this.goToQuestion}>
                        { this.props.language ? pathOr(buttonTranslation, ['publicSurvey', 'translation', this.props.language, 'continueButton'], respondent) : buttonTranslation}
                    </Button>
                </Footer>;
            default:
                return <NoMatch />;
        }
    }

    render() {
        const { pending, error, respondent, location } = this.props;
        const publicSurvey = pathOr({}, ['publicSurvey'], respondent);

        return pending && !respondent ?
            <div>Загрузка...</div> :
            error ? <NoMatch /> :
                path(['pda', 'agreement'], respondent) || isDemo(location) ?
                    <Container>
                        { this.renderInfoSection(publicSurvey) }
                        { this.renderData() }
                    </Container> :
                    <Container>
                        { this.renderPdaForm()}
                    </Container>;
    }
}

class Response extends Component {
    state = {
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    render() {
        const {
            location,
            match: { params: { id, num } },
            getSurveyRespondent: { meta, data },
        } = this.props;

        const css = path(['publicSurvey', 'projectSettings', 'theme', 'css'], data);
        const infoScheme = pathOr({}, ['infoScheme'], data);
        const isEnd = endsWith('thanks', location.pathname);
        const loading = meta.pending || (!meta.lastSucceedAt && !meta.success && !isEnd);

        if (this.state.error) {
            return <Wrapper>
                <ErrorWrapper>
                    <p>Ошибка - Не удалось загрузить тест</p>
                    <Button onClick={this.reloadPage}>Перезагрузить страницу</Button>
                </ErrorWrapper>
            </Wrapper>;
        }

        if (meta.error && !isEnd) {
            return (
                <Wrapper>
                    <VerticalCenter>
                        <ErrorTitle>{path(['status'], meta.error)}</ErrorTitle>
                        <ErrorDescription>Страница не найдена</ErrorDescription>
                    </VerticalCenter>
                </Wrapper>
            );
        }

        return <Fragment>
            { css && <style>{ css }</style> }
            <Wrapper
                designScheme={path(['designScheme'], infoScheme)}
                pending={loading}
            >
                { !loading ?
                    <Switch>
                        <Route path={`/survey_respondents/${id}`} exact render={props =>
                            <RespondentMain
                                {...props}
                                getSurveyRespondent={this.props.getSurveyRespondent}
                                postSurveyRespondentPda={this.props.postSurveyRespondentPda}
                                postSurveyRespondentStart={this.props.postSurveyRespondentStart}
                                postSurveyRespondentFinish={this.props.postSurveyRespondentFinish}
                                id={id}
                                respondent={data} />
                        } />
                        <Route path={`/survey_respondents/${id}/driver/:num`} render={props =>
                            <Question
                                key={num}
                                {...props}
                                getSurveyRespondent={this.props.getSurveyRespondent}
                                postSurveyRespondentStart={this.props.postSurveyRespondentStart}
                                postSurveyRespondentFinish={this.props.postSurveyRespondentFinish}
                                putSurveyRespondentQuestionAction={this.props.putSurveyRespondentQuestionAction}
                                id={id}
                                respondent={data}
                                drivers={getDrivers(data)} />
                        } />
                        <Route path={`/survey_respondents/${id}/thanks`} render={props =>
                            <Thanks
                                {...props}
                                id={id}
                                respondent={data}
                            />
                        } />
                        <Route component={NoMatch} />
                    </Switch>
                    : null}
            </Wrapper>
        </Fragment>;
    }
}

export default asyncConnect(({ getSurveyResponseAction, postSurveyResponseFinishAction, postSurveyResponsePdaAction, postSurveyResponseStartAction }) => ({
    getSurveyRespondent: getSurveyResponseAction
        .withPayload(({ match, location }) => ({ id: match.params.id, token: getToken(location) }))
        .withSuccessHandler(({ getSurveyRespondent: { data }, postSurveyRespondentFinish, location }) => {
            const pages = getDrivers(data);
            const answers = filter(answer => !!answer, pathOr([], ['response', 'answers'], data));
            const theme = path(['publicSurvey', 'projectSettings', 'theme'], data);
            const finished = (answers.length === pages.length) && (data.status !== 'sent');

            if (theme && !isEmpty(theme)) {
                setTheme(theme.name || 'ancorTheme', theme.logo);
            }

            if (finished && !isDemo(location)) {
                postSurveyRespondentFinish.dispatch({
                    id: data.id,
                });
                return;
            }
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    postSurveyRespondentPda: postSurveyResponsePdaAction
        .withSuccessHandler(({ match, history, getSurveyRespondent }) => {
            getSurveyRespondent.refresh();
        })
        .withOptions({ resetOnUnmount: true }),
    postSurveyRespondentStart: postSurveyResponseStartAction
        .withSuccessHandler(({ match, history, postSurveyRespondentFinish, getSurveyRespondent: { data } }) => {
            const pages = getDrivers(data);

            if (!pages.length) {
                postSurveyRespondentFinish.dispatch({
                    id: match.params.id
                });
            } else {
                history.push(`/survey_respondents/${ match.params.id}/driver/0`);
            }
        })
        .withOptions({ resetOnUnmount: true }),
    postSurveyRespondentFinish: postSurveyResponseFinishAction
        .withSuccessHandler(({ history, match }) => {
            history.push(`/survey_respondents/${match.params.id}/thanks`);
        })
        .withOptions({ resetOnUnmount: true }),
}))(Response);
