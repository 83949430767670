import React, { Component } from 'react';
import styled from 'styled-components';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const StyledInput = styled.input`
    outline: 0;
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    font-size: 16px;
    background-color: #ededed;
    border: 1px solid transparent;
    font-weight: bold;
    color: #2b3d4f;
    background-image: none;
    appearance: none;
`;

class Input extends Component {
    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { disabled, input: { value }, placeholder } = this.props;

        return <StyledInput
            type='text'
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            onChange={this.onChange} />
    }
}

export default withFieldWrapper(Input);
