import { createAsyncAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';
import * as handlers from './handlers';

export const getTests = createAsyncAction(actions.GET_TESTS, handlers.getTestsHandler, []);
export const getTestsPda = createAsyncAction(actions.GET_TESTS_PDA, handlers.getTestsPdaHandler);
export const postTestsPda = createAsyncAction(actions.POST_TESTS_PDA, handlers.postTestsPdaHandler);
export const getRespondent = createAsyncAction(actions.GET_RESPONDENT, handlers.getRespondentHandler);
export const postRespondentPda = createAsyncAction(actions.POST_RESPONDENT_PDA, handlers.postRespondentPdaHandler);
export const postRespondentStart = createAsyncAction(actions.POST_RESPONDENT_START, handlers.postRespondentStartHandler);
export const postRespondentFinish = createAsyncAction(actions.POST_RESPONDENT_FINISH, handlers.postRespondentFinishHandler);
export const getRespondentQuestion = createAsyncAction(actions.GET_RESPONDENT_QUESTION, handlers.getRespondentQuestionHandler);
export const postRespondentAnswer = createAsyncAction(actions.POST_RESPONDENT_ANSWER, handlers.postRespondentAnswerHandler);
export const postRespondentStartFinish = createAsyncAction(actions.POST_RESPONDENT_START_FINISH, handlers.postRespondentStartFinishHandler);

export const getAudit = createAsyncAction(actions.GET_AUDIT, handlers.getAuditHandler);
export const postAuditStart = createAsyncAction(actions.POST_AUDIT_START, handlers.postAuditStartHandler);
export const postAuditFinish = createAsyncAction(actions.POST_AUDIT_FINISH, handlers.postAuditFinishHandler);
export const getAuditQuestion = createAsyncAction(actions.GET_AUDIT_QUESTION, handlers.getAuditQuestionHandler);
export const postAuditAnswer = createAsyncAction(actions.POST_AUDIT_ANSWER, handlers.postAuditAnswerHandler);
export const postAuditStartFinish = createAsyncAction(actions.POST_AUDIT_START_FINISH, handlers.postAuditStartFinishHandler);

export const getSurvey = createAsyncAction(actions.GET_SURVEY, handlers.getSurveyHandler, []);
export const getSurveyRespondent = createAsyncAction(actions.GET_SURVEY_RESPONDENT, handlers.getSurveyRespondentHandler);
export const postSurveyRespondentPda = createAsyncAction(actions.POST_SURVEY_RESPONDENT_PDA, handlers.postSurveyRespondentPdaHandler);
export const postSurveyRespondentStart = createAsyncAction(actions.POST_SURVEY_RESPONDENT_START, handlers.postSurveyRespondentStartHandler);
export const postSurveyRespondentFinish = createAsyncAction(actions.POST_SURVEY_RESPONDENT_FINISH, handlers.postSurveyRespondentFinishHandler);
export const getSurveyRespondentQuestion = createAsyncAction(actions.GET_SURVEY_RESPONDENT_QUESTION, handlers.getSurveyRespondentQuestionHandler);
export const putSurveyRespondentQuestion = createAsyncAction(actions.PUT_SURVEY_RESPONDENT_QUESTION, handlers.putSurveyRespondentQuestionHandler);
export const postSurveyRespondentStartFinish = createAsyncAction(actions.POST_SURVEY_RESPONDENT_START_FINISH, handlers.postSurveyRespondentStartFinishHandler);

export const getRegions = createAsyncAction(actions.GET_REGIONS, handlers.getRegionsHandler);
export const getSpecialities = createAsyncAction(actions.GET_SPECIALITIES, handlers.getSpecialitiesHandler);

export const getCompany = createAsyncAction(actions.GET_COMPANY, handlers.getCompanyHandler);

export const getRespondentReport = createAsyncAction(actions.GET_RESPONDENT_REPORT, handlers.getRespondentReportHandler);
