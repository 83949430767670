import React from 'react';

export default ({ executionTime, timeLimited }) => {
    const hours = Math.floor(executionTime / 3600);
    const minutes = Math.floor((executionTime - hours * 3600) / 60);
    const seconds = executionTime - hours * 3600 - minutes * 60;

    return <span>
        { !timeLimited ? '-' : `${hours}:${minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00'}:${seconds ? (seconds < 10 ? `0${seconds}` : seconds) : '00'}` }
    </span>;
}
