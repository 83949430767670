import React from 'react';
import { pathEq } from 'ramda';
import styled from 'styled-components';

import withFieldWrapper from '../withFieldWrapper';

const LabelRadio = styled.label`
    position: relative;
    input {
        position: absolute;
        margin-left: -15px;
        opacity: 0;
        &:checked + .checked-box {
            box-shadow: rgb(43 61 79) 0px 0px 0px 2px inset, rgb(255 255 255) 0px 0px 0px 6px inset, rgb(43 61 79) 0px 0px 0px 12px inset, transparent 0px 0px 0px 24px;
        }
    }
    .checked-box {
        cursor: pointer;
        display: block;
        margin-right: 10px;
        float: left;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #ededed;
        border: 0;
        box-shadow: none;
        transition: box-shadow 280ms cubic-bezier(0.82, 0.14, 0.44, 0.41) 0s;
    }
    .checked-label {
        line-height: 24px;
        margin-left: 8px;
    }
`;

const SingleSelect = props => {
    const { originalQuestions, questions, input, language, translatedQuestions, disabled } = props;
    const onChange = (value, valueIndex) => {
        const values = {};
        originalQuestions.forEach((question, index) => {
            values[index] = index === valueIndex ? value : 0;
        });
        props.onChange(values);
    }

    return <table>
            <tbody>
                { questions.map((question, index) =>
                    <tr key={`question-${index}`}>
                        <td
                            className={'answer-text'}
                        >
                            <LabelRadio className='radio' id={`answer-${index}-${1}`}>
                                <input
                                    type='radio'
                                    checked={pathEq([originalQuestions.indexOf(question)], 1, input.value || [])}
                                    value={1}
                                    disabled={disabled}
                                    onChange={e => onChange(Number(e.target.value), originalQuestions.indexOf(question))} />
                                <span className="checked-box" />
                                <span className="checked-label" dangerouslySetInnerHTML={{ __html: (language && translatedQuestions[index] ? translatedQuestions[index] : question) }} />
                            </LabelRadio>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>;
}

export default withFieldWrapper(SingleSelect);
