import React from 'react';
import { is } from 'ramda';
import styled from 'styled-components';

import withFieldWrapper from '../withFieldWrapper';

const Label = styled.label`
    position: relative;
    display: block;
    width: 100%;
    margin-top: 5px;
    clear: both;
    input {
        position: absolute;
        margin-left: -15px;
        opacity: 0;
        &:checked + .checked-box {
            box-shadow: rgb(43 61 79) 0px 0px 0px 2px inset, rgb(255 255 255) 0px 0px 0px 6px inset, rgb(43 61 79) 0px 0px 0px 12px inset, transparent 0px 0px 0px 24px;
        }
    }
    .checked-box {
        cursor: pointer;
        display: block;
        margin-right: 10px;
        float: left;
        min-width: 24px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #ededed;
        border: 0;
        box-shadow: none;
        transition: box-shadow 280ms cubic-bezier(0.82, 0.14, 0.44, 0.41) 0s;
    }
`;

const Radio = props => {
    const { input: { value, name }, options } = props;
    const items = is(Array, options) ? options : [];
    const onChange = e => props.onChange(e.target.value);

    return <div>{items.map((option, index) =>
        <Label className='radio' key={`${name}-${index}`}>
            <input
                type='radio'
                value={option}
                checked={option === value}
                onChange={onChange} /><span className="checked-box" /> { option }
        </Label>
    )}</div>;
}

export default withFieldWrapper(Radio);
